import { ITimezone } from 'react-timezone-select';

export const GLOBAL_ADMIN = {
  buttons: {
    add: 'Add New Event',
    remove: 'Clear Form',
    update: 'Update Event',
  },
  modal: {
    title: 'Title',
    url: 'URL',
    startDate: 'Start Date',
    endDate: 'End Date',
    timeZone: 'Time Zone',
    eventAdmins: 'Event admins',
  },
};

export const DEFAULT_EVENT_STATE = {
  name: '',
  url: '',
  startDate: new Date(),
  endDate: new Date(),
  timeZone: '0',
  isActive: false,
  eventAdminList: [],
};

export type Event = {
  name: string;
  url: string;
  startDate: Date;
  endDate: Date;
  timeZone: string;
  isActive?: boolean;
  eventAdminList: string[];
};

export const REGEX_NAME = /^[ A-Za-z0-9-]*$/;

export enum ERROR_FIELD {
  REQUIRED = 'required',
  TITLE = 'title',
  ADMIN = 'admin',
}

export const ERROR_MESSAGE = {
  required: 'This field is required',
  title: "Title shouldn't be longer than 15 symbols",
  admin: 'At least one admin is needed for event',
};

export const DEFAULT_TIMEZONE: ITimezone = {
  abbrev: 'GMT',
  altName: 'ETC/GMT',
  label: '(GMT+0:00) UTC',
  offset: 0,
  value: 'Etc/GMT',
};
