import React, { useEffect } from 'react';
import { labWarzApi } from '@root/api/LabWarzApi';
import { useStore } from '@root/contextProvider/storeContext';
import { observer } from 'mobx-react-lite';
import iconCurrent from '@images/icon-process-current.svg';
import iconFuture from '@images/icon-process.svg';
import iconDone from '@images/icon-done.svg';
import './ScenarioRoadmap.scss';

export const ScenarioRoadmap = observer((): JSX.Element => {
  const store = useStore();

  useEffect(() => {
    labWarzApi.Game.getScenarioRoadmap(store.getScenario())
      .then((res) => {
        store.setRoadmap(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <section className={'scenario-roadmap'}>
      {store.roadmap && store.state ? (
        <>
          <div className={'scenario-roadmap__title'}>{store.roadmap.title}</div>
          <ul className={'scenario-roadmap__list'}>
            <li className={'scenario-roadmap__list__task'}>
              <div
                className={`scenario-roadmap__list__task__label ${
                  store.state.taskId > -1 ? 'done' : 'current'
                }`}
              >
                {store.roadmap.intro}
              </div>
            </li>
            {store.roadmap.tasks.map((task, i) => (
              <li key={i} className={'scenario-roadmap__list__task'}>
                <div
                  className={`scenario-roadmap__list__task__label ${
                    store.state.taskId >= i ? 'done' : 'future'
                  }`}
                >
                  {task.title}
                </div>
                {task.steps && (
                  <div>
                    {task.steps.map((step, j) => (
                      <span
                        className={`scenario-roadmap__list__task__step ${
                          store.state.taskId === i && store.state.stepId === j
                            ? 'current'
                            : store.state.taskId > i
                            ? 'done'
                            : store.state.stepId > j && store.state.taskId == i
                            ? 'done'
                            : 'future'
                        }`}
                        key={j}
                      >
                        <img
                          src={
                            store.state.taskId === i && store.state.stepId === j
                              ? iconCurrent
                              : store.state.taskId > i
                              ? iconDone
                              : store.state.stepId > j &&
                                store.state.taskId == i
                              ? iconDone
                              : iconFuture
                          }
                          alt={'ProcessLogo'}
                        />
                        {step}
                      </span>
                    ))}
                  </div>
                )}
              </li>
            ))}
            <li className={'scenario-roadmap__list__task'}>
              <div
                className={`scenario-roadmap__list__task__label ${
                  store.state.taskId === 1000 ? 'current' : 'future'
                }`}
              >
                {store.roadmap.outro}
              </div>
            </li>
          </ul>
        </>
      ) : null}
    </section>
  );
});
