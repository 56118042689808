import React, { useEffect } from 'react';
import { labWarzApi } from '@root/api/LabWarzApi';
import { useStore } from '@root/contextProvider/storeContext';
import { ROUTES } from '@root/routes';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import Loading from '@components/Loading/Loading';

export const LoginVeeamId = observer((): JSX.Element => {
  const [searchParams, _] = useSearchParams();
  const code = searchParams.get('code');
  const store = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    code &&
      labWarzApi.Auth.authToken({ code: code })
        .then((res) => {
          localStorage.setItem('isAuthenticated', JSON.stringify(res.data));
          labWarzApi.Users.getInfo()
            .then((res) => store.setUser(res.data))
            .catch((error) => {
              console.error(error);
              store.setUser(null);
            });
        })
        .finally(() => {
          store.setEvent(null);
          navigate(ROUTES.labwarz.global.root);
        });
  }, []);

  return <Loading />;
});
