import React, { useEffect, useState } from 'react';
import { labWarzApi } from '@root/api/LabWarzApi';
import { useStore } from '@root/contextProvider/storeContext';
import { QUIZ } from '@components/content/Quiz/Quiz.const';

import './Quiz.scss';
import { Button } from '@components/kit/Button/Button';
import { ArrayService } from '@root/services/Array.service';

interface IQuizProps {
  img?: string;
  question: string;
  answers: string[];
  setScore: (v: number) => void;
}

export const Quiz = ({
  img,
  question,
  answers,
  setScore,
}: IQuizProps): JSX.Element => {
  const [shuffledAnswers, setShuffledAnswers] = useState<string[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const store = useStore();

  useEffect(() => {
    setShuffledAnswers(ArrayService.shuffle(answers));
  }, [answers]);

  const handleSubmitAnswer = async () => {
    setSelectedAnswer('');
    store.setIsLoading(true);
    await labWarzApi.Game.submitQuizAnswer({
      id_task: store.state.taskId,
      id_step: store.state.stepId,
      answer: selectedAnswer,
      eventId: store.currentEvent.id,
      scenarioId: store.getScenario(),
      resultId: store.user.resultId,
    })
      .then((res) => {
        if (res.data['outro']) {
          store.setStateOutro(res.data['outro'].src);
          setScore(res.data['outro'].score);
        } else {
          store.setState(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        store.setIsLoading(false);
      });
  };

  return (
    <section className={'quiz'}>
      {img && (
        <div className={'quiz__image'}>
          <img src={img} alt={'QuestionImage'} width={800} height={500} />
        </div>
      )}
      <span className={'quiz__question'}>{question}</span>
      <aside className={'quiz__container'}>
        <div className={'quiz__container__answer-list'}>
          {shuffledAnswers.map((answer, i) => (
            <div
              key={i}
              className={`quiz__container__answer-list__answer ${
                selectedAnswer === answer ? 'selected' : ''
              }`}
              onClick={() => setSelectedAnswer(answer)}
            >
              {answer}
            </div>
          ))}
        </div>
      </aside>

      <div className={'quiz__button'}>
        <Button
          label={QUIZ.submit}
          onClick={handleSubmitAnswer}
          disabled={!selectedAnswer}
        />
      </div>
    </section>
  );
};
