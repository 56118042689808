import React, { useEffect, useState } from 'react';
import { Leaderboard } from '@components/Leaderboard/Leaderboard';
import { LayoutInner } from '@components/Layout/LayoutInner/LayoutInner';
import { labWarzApi } from '@root/api/LabWarzApi';
import { LEADERBOARD_PAGE } from '@root/pages/LeaderboardPage/LeaderboardPage.const';

import './LeaderboardPage.scss';
import { LEADERBOARD_TYPE } from '@components/Leaderboard/Leaderboard.const';
import { User } from '@root/domain/entities/user/user.entity';
import { useStore } from '@root/contextProvider/storeContext';

export const LeaderboardPage = (): JSX.Element => {
  const [completedNumberOfUsers, setCompletedNumberOfUsers] =
    useState<number>(0);
  const [completedNumberOfUsersInPerson, setCompletedNumberOfUsersInPerson] =
    useState<number>(0);
  const [veeamUsers, setVeeamUsers] = useState<User[]>([]);

  const [selectedScoreboard, setSelectedScoreboard] =
    useState<LEADERBOARD_TYPE>(LEADERBOARD_TYPE.HARD);

  const handleCheckState = (state: LEADERBOARD_TYPE) => {
    setSelectedScoreboard(state);
  };

  const store = useStore();

  useEffect(() => {
    if (store.currentEvent) {
      labWarzApi.Users.getAllUsersCompleted(store.currentEvent.id)
        .then((res) => {
          setCompletedNumberOfUsers(
            res.data.filter((user) => user.scenarioId === 0).length,
          );
          setCompletedNumberOfUsersInPerson(
            res.data.filter((user) => user.scenarioId === 1).length,
          );
        })
        .catch((error) => console.error(error));

      labWarzApi.Users.getAllUsersCompletedVeeam(store.currentEvent.id).then(
        (res) => {
          setVeeamUsers(res.data);
        },
      );

      labWarzApi.Event.getEventSettings(store.currentEvent.id).then((res) => {
        store.setShowUsersScore(res.data.showScore);
      });

      labWarzApi.Scenario.getScenarioList(store.currentEvent.id).then((res) => {
        const isFirstScenario = res.data[0].scenarioId === 1;

        store.setFirstScenarioAvailability(
          isFirstScenario ? res.data[0].isAvailable : res.data[1].isAvailable,
        );
        store.setSecondScenarioAvailability(
          !isFirstScenario ? res.data[0].isAvailable : res.data[1].isAvailable,
        );
      });
    }
  }, [store.currentEvent]);

  const getCompletedCount = {
    [LEADERBOARD_TYPE.SIMPLE]: completedNumberOfUsers,
    [LEADERBOARD_TYPE.HARD]: completedNumberOfUsersInPerson,
    [LEADERBOARD_TYPE.VEEEAM]: veeamUsers.length,
  };

  return (
    <LayoutInner>
      <div className={'leaderboard-page-head'}>
        <p className={'leaderboard-page-head__statistic'}>
          {LEADERBOARD_PAGE.totalCompleted}
          {getCompletedCount[selectedScoreboard]}
        </p>
      </div>

      <div style={{ width: '60%' }}>
        <Leaderboard checkState={handleCheckState} veeamUsers={veeamUsers} />
      </div>
    </LayoutInner>
  );
};
