import { HEADER_CONST } from '@components/Header/Header.const';
import LogoNew from '@images/logoNew.svg';
import React from 'react';

import { IEvent } from '@root/api/api.interface';
import { User } from '@root/domain/entities/user/user.entity';
import { ADMIN_ROLE } from '@root/store/Store.const';
import { observer } from 'mobx-react-lite';
import { BiExit } from 'react-icons/bi';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { RiAdminLine } from 'react-icons/ri';
import './Header.scss';

interface IHeaderProps {
  handleLogin: () => void;
  user: User | null;
  handleLogout: () => void;
  isAdmin: ADMIN_ROLE;
  event: IEvent;
  goToGlobalAdminPage: () => void;
  goToEventAdminPage: () => void;
}

export const Header = observer(
  ({
    handleLogin,
    handleLogout,
    user,
    isAdmin,
    event,
    goToEventAdminPage,
    goToGlobalAdminPage,
  }: IHeaderProps): JSX.Element => {
    return (
      <header className={'header'}>
        <a className={'header__logo'} href={'/'}>
          <img src={LogoNew} alt={'Logo'} />
        </a>

        <div className={'header__user'}>
          {user ? (
            <div className={'header__user__profile'}>
              <div>Hi!, {user.name}</div>

              {isAdmin === ADMIN_ROLE.GLOBAL ? (
                <RiAdminLine
                  size={18}
                  className={'header__user__profile__global-admin'}
                  onClick={goToGlobalAdminPage}
                />
              ) : null}

              {(isAdmin === ADMIN_ROLE.ADMIN ||
                isAdmin === ADMIN_ROLE.GLOBAL) &&
              event ? (
                <MdOutlineAdminPanelSettings
                  size={18}
                  className={'header__user__profile__admin'}
                  onClick={goToEventAdminPage}
                />
              ) : null}

              <BiExit
                className={'header__user__profile__logout'}
                size={18}
                onClick={handleLogout}
              />
            </div>
          ) : (
            <div onClick={handleLogin} className={'header__user__login'}>
              {' '}
              {HEADER_CONST.login}
            </div>
          )}
        </div>
      </header>
    );
  },
);
