import React, { useEffect } from 'react';
import './Event.scss';
import { LOGIN, SCENARIO } from '@components/Event/Event.const';
import { ROUTES } from '@root/routes';
import { ScenarioCard } from '@components/ScenarioCard/ScenarioCard';
import { LayoutInner } from '@components/Layout/LayoutInner/LayoutInner';
import { useNavigate, useParams } from 'react-router';
import { Leaderboard } from '@components/Leaderboard/Leaderboard';
import { observer } from 'mobx-react-lite';
import { useStore } from '@root/contextProvider/storeContext';
import { labWarzApi } from '@root/api/LabWarzApi';
import { Navigate } from 'react-router-dom';

interface ILoginProps {
  isOnSiteOpened: boolean;
  isVirtualOpened: boolean;
  isEventOpen: boolean;
}

export const Event = observer(
  ({
    isOnSiteOpened,
    isVirtualOpened,
    isEventOpen,
  }: ILoginProps): JSX.Element => {
    const store = useStore();
    const navigate = useNavigate();
    const handlePlayScenario = (scenario: SCENARIO) => {
      const user = store.user;
      if (user) {
        store.setScenario(scenario);
        localStorage.setItem(
          'scenario',
          JSON.stringify({ scenarioId: scenario }),
        );
        return navigate(
          ROUTES.labwarz.event.info.replace(':event', store.currentEvent.url),
        );
      } else {
        return labWarzApi.Auth.loginVeeamId().then((res) => {
          window.location.assign(res.data['url']);
        });
      }
    };

    return (
      <LayoutInner>
        <section className={'login-page'}>
          <h1 className={'login-page__label'}>
            {LOGIN.labwarz}
            {store.currentEvent.name}
          </h1>
          <div className={'login-page__option-list'}>
            {isVirtualOpened && (
              <ScenarioCard
                scenarioType={LOGIN.scenario.simple}
                buttonLabel={LOGIN.button.simple}
                onClick={() => handlePlayScenario(SCENARIO.FIRST)}
                buttonId={LOGIN.button.idSimple}
                note={LOGIN.note.simple}
                available={isVirtualOpened && isEventOpen}
                availableText={LOGIN.availableText}
              />
            )}
            {isOnSiteOpened && (
              <ScenarioCard
                scenarioType={LOGIN.scenario.hard}
                buttonLabel={LOGIN.button.hard}
                onClick={() => handlePlayScenario(SCENARIO.SECOND)}
                buttonId={LOGIN.button.idHard}
                available={isOnSiteOpened && isEventOpen}
                availableText={LOGIN.availableText}
              />
            )}
          </div>
          {store.user ? (
            <div className={'login-page__leaderboard'}>
              <Leaderboard />
            </div>
          ) : null}
        </section>
      </LayoutInner>
    );
  },
);
