import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '@root/routes';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { useStore } from '@root/contextProvider/storeContext';
import { labWarzApi } from '@root/api/LabWarzApi';
import { observer } from 'mobx-react-lite';
import { IEvent } from '@root/api/api.interface';
import { LayoutInner } from '@components/Layout/LayoutInner/LayoutInner';
import Loading from '@components/Loading/Loading';

interface IProtectedRouteProps {
  element: JSX.Element;
  event?: IEvent;
}

export const ProtectedRoute = observer(
  ({ element, event }: IProtectedRouteProps): JSX.Element => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');

    const render = () => {
      if (isAuthenticated) {
        if (event === undefined) {
          return <Loading />;
        } else if (event === null) {
          return <Navigate to={ROUTES.labwarz.global.root} replace />;
        } else {
          return element;
        }
      } else {
        labWarzApi.Auth.loginVeeamId().then((res) => {
          window.location.assign(res.data['url']);
        });
      }
    };
    return render();
  },
);
