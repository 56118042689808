import { Button } from '@components/kit/Button/Button';
import React from 'react';
import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from 'react-player/types/lib';
import './Video.scss';
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

interface IVideoProps {
  src?: string;
  title?: string;
  onClick?: () => void;
}

export const Video = ({ src, title, onClick }: IVideoProps): JSX.Element => {
  return (
    <section className={'video'}>
      <aside className={'video__wrapper'}>
        <ReactPlayer
          className={'video__wrapper__player'}
          url={src}
          playing
          controls
          width="970px"
          height="550px"
          muted={false}
        />

        {/* Temporary solution*/}
        {/* <div className="smartphone">
          <div className="content">
            <iframe
              src={`${src}?rel=0&autoplay=1`}
              style={{ width: '100%', border: 'none', height: '100%' }}
            />
          </div>
        </div> */}
      </aside>

      <Button label={title} onClick={onClick} />
    </section>
  );
};
