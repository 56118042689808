import React, { useEffect, useState } from 'react';
import { User } from '@root/domain/entities/user/user.entity';
import {
  LEADERBOARD_PAGE,
  LEADERBOARD_TYPE,
} from '@components/Leaderboard/Leaderboard.const';

import { labWarzApi } from '@root/api/LabWarzApi';
import { ArrayService } from '@root/services/Array.service';
import { observer } from 'mobx-react-lite';

import './Leaderboard.scss';
import { useStore } from '@root/contextProvider/storeContext';
import Loading from '@components/Loading/Loading';

interface ILeaderboardProps {
  numberOfUsers?: number;
  checkState?: (type: LEADERBOARD_TYPE) => void;
  veeamUsers?: User[];
}

export const Leaderboard = observer(
  ({
    numberOfUsers = 15,
    checkState,
    veeamUsers,
  }: ILeaderboardProps): JSX.Element => {
    const store = useStore();
    const [showScore, setShowScore] = useState<boolean>(false);
    const [virtualList, setVirtualList] = useState<User[]>([]);
    const [onSiteList, setOnSiteList] = useState<User[]>([]);
    const [shownList, setShownList] = useState<User[]>([]);
    const [selectedScoreboard, setSelectedScoreboard] =
      useState<LEADERBOARD_TYPE>(LEADERBOARD_TYPE.SIMPLE);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
      setSelectedScoreboard(
        store.isScenario1Active && store.isScenario2Active
          ? LEADERBOARD_TYPE.SIMPLE
          : store.isScenario1Active
          ? LEADERBOARD_TYPE.SIMPLE
          : LEADERBOARD_TYPE.HARD,
      );
    }, [store.isScenario1Active, store.isScenario2Active]);

    useEffect(() => {
      setIsLoading(true);
      store.currentEvent &&
        labWarzApi.Users.getAllUsersCompleted(store.currentEvent.id)
          .then((res) => {
            const virtual = res.data.filter((user) => {
              return user.scenarioId === 0;
            });
            const onsite = res.data.filter((user) => {
              return user.scenarioId === 1;
            });
            setVirtualList(virtual);
            setOnSiteList(onsite);
            setShownList(virtual);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => setIsLoading(false));
    }, [store.currentEvent]);

    useEffect(() => {
      setShownList(
        store.isScenario1Active && store.isScenario2Active
          ? virtualList
          : store.isScenario1Active
          ? virtualList
          : onSiteList,
      );
    }, [
      store.isScenario1Active,
      store.isScenario2Active,
      virtualList,
      onSiteList,
    ]);

    const handleSelectScoreboard = (type: LEADERBOARD_TYPE) => {
      if (type === LEADERBOARD_TYPE.SIMPLE) {
        setShownList(virtualList);
      } else if (type === LEADERBOARD_TYPE.HARD) {
        setShownList(onSiteList);
      } else {
        setShownList(veeamUsers);
      }
      setSelectedScoreboard(type);
      if (checkState) {
        checkState(type);
      }
    };

    useEffect(() => {
      setShowScore(store.getShowUsersScore());
    }, [store.showUsersScore]);

    return isLoading ? (
      <Loading />
    ) : (
      <div className={'leaderboard-page'}>
        <span className={'leaderboard-page__label'}>
          {LEADERBOARD_PAGE.leaderboard}
        </span>
        <div className={'leaderboard-page__tab-list'}>
          {store.isScenario1Active && (
            <span
              className={`leaderboard-page__tab-list__tab--${
                selectedScoreboard === LEADERBOARD_TYPE.SIMPLE
                  ? 'underline'
                  : 'default'
              }`}
              onClick={() => handleSelectScoreboard(LEADERBOARD_TYPE.SIMPLE)}
            >
              {LEADERBOARD_PAGE.scenario.simple}
            </span>
          )}
          {store.isScenario2Active && (
            <span
              className={`leaderboard-page__tab-list__tab--${
                selectedScoreboard === LEADERBOARD_TYPE.HARD
                  ? 'underline'
                  : 'default'
              }`}
              onClick={() => handleSelectScoreboard(LEADERBOARD_TYPE.HARD)}
            >
              {LEADERBOARD_PAGE.scenario.hard}
            </span>
          )}

          {veeamUsers && (
            <span
              className={`leaderboard-page__tab-list__tab--${
                selectedScoreboard === LEADERBOARD_TYPE.VEEEAM
                  ? 'underline'
                  : 'default'
              }`}
              onClick={() => handleSelectScoreboard(LEADERBOARD_TYPE.VEEEAM)}
            >
              {LEADERBOARD_PAGE.scenario.veeam}
            </span>
          )}
        </div>
        <ul className={'leaderboard-page__list'}>
          <li className={'leaderboard-page__list__header'}>
            <span>{LEADERBOARD_PAGE.table.name}</span>
            {(showScore || selectedScoreboard === LEADERBOARD_TYPE.VEEEAM) && (
              <span>{LEADERBOARD_PAGE.table.score}</span>
            )}
          </li>
          {(showScore || selectedScoreboard === LEADERBOARD_TYPE.VEEEAM
            ? shownList
                .filter((user) => user.resultIsShown)
                .slice(0, numberOfUsers)
            : ArrayService.shuffle(
                shownList
                  .filter((user) => user.resultIsShown)
                  .slice(0, numberOfUsers),
              )
          ).map((user, i) => (
            <li key={i} className={'leaderboard-page__list__item'}>
              <span>{user.name}</span>
              {(showScore ||
                selectedScoreboard === LEADERBOARD_TYPE.VEEEAM) && (
                <span>{user.score}</span>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  },
);
