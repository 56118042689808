import React, { useEffect, useState } from 'react';
import { GlobalAdmin } from '@components/GlobalAdmin/GlobalAdmin';
import { IEventFull } from '@root/api/api.interface';
import { labWarzApi } from '@root/api/LabWarzApi';
import { useNavigate } from 'react-router';
import { ROUTES } from '@root/routes';
import Loading from '@components/Loading/Loading';
import { useStore } from '@root/contextProvider/storeContext';

const GlobalAdminPage = (): JSX.Element => {
  const navigate = useNavigate();
  const store = useStore();
  const [data, setData] = useState<IEventFull[] | undefined>(undefined);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    return labWarzApi.Event.getEventListFullData()
      .then((res) => setData(res.data))
      .catch((error) => {
        console.error(error);
        store.setEvent(null);
        navigate(ROUTES.labwarz.global.root);
      });
  };
  return data && store.user ? (
    <GlobalAdmin data={data} refreshData={refreshData} />
  ) : (
    <Loading />
  );
};

export default GlobalAdminPage;
