import React from 'react';
import { textStyle } from '@components/kit/Text/Text.style';

interface ITextProps {
  children: string;
  weight?: number;
}

export const Text = ({ children, weight }: ITextProps): JSX.Element => {
  return <span style={textStyle(weight)}>{children}</span>;
};
