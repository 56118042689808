import React from 'react';
import { FINISHED_GAME_CONST } from '@components/FinishedGame/FinishedGame.const';
import { useLocation } from 'react-router';

import './FinishedGame.scss';
import { LayoutInner } from '@components/Layout/LayoutInner/LayoutInner';

type LocationState = { score: number };

export const FinishedGame = (): JSX.Element => {
  const { state } = useLocation();
  const { score } = state as LocationState;
  return (
    <LayoutInner>
      <div className={'finished-game__wrapper'}>
        <span className={'finished-game__wrapper__text'}>
          {FINISHED_GAME_CONST.congrats}
        </span>
        <span className={'finished-game__wrapper__score'}>
          {FINISHED_GAME_CONST.score}
          {score}
        </span>
      </div>
    </LayoutInner>
  );
};
