import React, { useEffect } from 'react';

import './PeoplePicker.scss';
import AsyncSelect from 'react-select/async';
import { labWarzApi } from '@root/api/LabWarzApi';
import { OptionType } from '@components/kit/PeoplePicker/PeoplePicker.type';

interface IPeoplePickerProps {
  selectedOptionList: OptionType[];
  setSelectedOptionList: (val: OptionType[]) => void;
}

export const PeoplePicker = ({
  selectedOptionList,
  setSelectedOptionList,
}: IPeoplePickerProps): JSX.Element => {
  const loadOptions = async (inputText: string) => {
    const res = await labWarzApi.Users.searchAdmin(inputText);
    return await res.data.map((user) => ({
      label: user.name,
      value: user.email,
    }));
  };

  return (
    <AsyncSelect
      isMulti
      cacheOptions
      loadOptions={loadOptions}
      value={selectedOptionList}
      onChange={(val) => setSelectedOptionList(val)}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};
