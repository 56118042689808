import React from 'react';

import './ScenarioCard.scss';

interface ILoginOptionProps {
  scenarioType: string;
  buttonLabel: string;
  onClick?: () => void;
  buttonId?: string;
  note?: string;
  available?: boolean;
  availableText?: string;
}

export const ScenarioCard = ({
  scenarioType,
  buttonLabel,
  onClick,
  buttonId,
  note,
  available = false,
  availableText,
}: ILoginOptionProps): JSX.Element => {
  return available ? (
    <div className={'login-option'} onClick={onClick} id={buttonId}>
      <div className={'login-option__info'}>
        <span className={'login-option__info__type'}>{scenarioType}</span>
      </div>
      <span className={'login-option__start'}>{buttonLabel}</span>
      <span></span>
    </div>
  ) : (
    <div className={'login-option-unavailable'}>
      <span className={'login-option-unavailable__disclaimer'}>
        {availableText}
      </span>
    </div>
  );
};
