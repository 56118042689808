import React from 'react';
import { Modal } from '@components/kit/Modal/Modal';
import { CUSTOM_MODAL } from '@components/kit/Modal/CustomModal/CustomModal.const';

interface ICustomModalProps {
  confirmAction: () => void;
  cancelAction: () => void;
  object: string;
  warningText: string;
}

import './CustomModal.scss';

const CustomModal = ({
  confirmAction,
  cancelAction,
  object,
  warningText,
}: ICustomModalProps): JSX.Element => {
  return (
    <Modal
      onConfirm={confirmAction}
      onClose={cancelAction}
      onReject={cancelAction}
    >
      <div className={'custom-modal'}>
        <div className={'custom-modal__warning'}>
          {warningText}
          <span className={'modal-reset__warning__name'}>{object} ?</span>
        </div>
        <div className={'modal-reset__warning-small'}>
          {CUSTOM_MODAL.warningSmallDelete}
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
