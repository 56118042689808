import { Map } from '@root/domain/entities/map/map.entiry';
import React from 'react';
import ImageMapper from 'react-img-mapper';

interface IMapperProps {
  src: string;
  map: Map;
  onSubmit: () => void;
  onClick: () => void;
}

export const Mapper = ({
  src,
  map,
  onSubmit,
  onClick,
}: IMapperProps): JSX.Element => {
  return (
    <ImageMapper
      src={src}
      map={map}
      onClick={onSubmit}
      onImageClick={onClick}
      active={false}
      width={1280}
      height={800}
    />
  );
};
