import React, { useEffect, useState } from 'react';
import { Video } from '@components/content/Video/Video';
import { Quiz } from '@components/content/Quiz/Quiz';
import { Screenshot } from '@components/content/Screenshot/Screenshot';
import { labWarzApi } from '@root/api/LabWarzApi';
import { useStore } from '@root/contextProvider/storeContext';
import { observer } from 'mobx-react-lite';
import { CONTENT_BODY } from '@components/ContentBody/ContentBody.const';
import { Modal } from '@components/kit/Modal/Modal';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { useNavigate } from 'react-router';
import { ROUTES } from '@root/routes';
import Loading from '@components/Loading/Loading';

export const ContentBody = observer((): JSX.Element => {
  const store = useStore();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(store.isLoading);
  }, [store.isLoading]);

  const handleStartGame = (): void => {
    labWarzApi.Game.startTheGame(
      store.currentEvent.id,
      store.getScenario(),
      store.user.resultId,
    )
      .then((res) => {
        store.setState(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleVideoNextStep = (): void => {
    store.setIsLoading(true);
    labWarzApi.Game.videoNextStep({
      id_task: store.state.taskId,
      id_step: store.state.stepId,
      eventId: store.currentEvent.id,
      scenarioId: store.getScenario(),
      resultId: store.user.resultId,
    })
      .then((res) => {
        if (res.data['outro']) {
          store.setStateOutro(res.data['outro'].src);
          setScore(res.data['outro'].score);
        } else {
          store.setState(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        store.setIsLoading(false);
      });
  };

  const handleSetModalIsOpen = () => {
    setIsModalOpen(true);
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
    localStorage.removeItem('aES-Fuhsdgrw-lofuit');
    store.setEvent(null);
    navigate(ROUTES.labwarz.global.root);
  };

  const body = {
    video: (
      <Video
        src={store.state?.body.src}
        title={
          store.state?.taskId === -1
            ? CONTENT_BODY.newGame
            : store.state?.taskId === 1000
            ? CONTENT_BODY.finishGame
            : CONTENT_BODY.nextStep
        }
        onClick={
          store.state?.taskId === -1
            ? handleStartGame
            : store.state?.taskId === 1000
            ? handleSetModalIsOpen
            : handleVideoNextStep
        }
      />
    ),
    quiz: (
      <Quiz
        img={store.state?.body.src}
        question={store.state?.body.question}
        answers={store.state?.body.answers}
        setScore={setScore}
      />
    ),
    screenshot: (
      <Screenshot
        src={store.state?.body.url}
        map={store.state?.body.map}
        setScore={setScore}
      />
    ),
  };
  return (
    <>
      {isLoading ? <Loading /> : body[store.state?.type]}
      {isModalOpen && (
        <Modal
          onConfirm={handleConfirm}
          onConfirmLabel={CONTENT_BODY.great}
          onClose={() => setIsModalOpen(false)}
        >
          <p>
            {CONTENT_BODY.congratulation}
            {score}
          </p>
        </Modal>
      )}
    </>
  );
});
