import { Header } from '@components/Header/Header';
import { labWarzApi } from '@root/api/LabWarzApi';
import { useStore } from '@root/contextProvider/storeContext';
import { ROUTES } from '@root/routes';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router';
import { LAYOUT_MAIN } from './LayoutMain.const';
import './LayoutMain.scss';

interface ILayoutMainProps {
  children: JSX.Element;
}

export const LayoutMain = observer(
  ({ children }: ILayoutMainProps): JSX.Element => {
    const store = useStore();
    const navigate = useNavigate();

    const handleLogin = () => {
      labWarzApi.Auth.loginVeeamId().then((res) => {
        window.location.assign(res.data['url']);
      });
    };

    const handleLogout = () => {
      store.setUser(null);
      store.setEvent(null);
      localStorage.removeItem('isAuthenticated');
      navigate(ROUTES.labwarz.global.root);
    };

    const goToEventAdminPage = () => {
      return navigate('/event/' + store.currentEvent.url + '/admin');
    };

    const goToGlobalAdminPage = () => {
      store.setEvent(null);
      navigate(ROUTES.labwarz.global.admin);
    };

    return (
      <div className={'layout-main'}>
        <section className={'layout-main__wrapper'}>
          <Header
            handleLogin={handleLogin}
            handleLogout={handleLogout}
            user={store.user}
            isAdmin={store.isAdmin}
            event={store.currentEvent}
            goToGlobalAdminPage={goToGlobalAdminPage}
            goToEventAdminPage={goToEventAdminPage}
          />
          <section className={'layout-main__wrapper__body'}>{children}</section>
        </section>
        <footer className={'layout-main__footer'}>
          <span className={'layout-main__footer__copyright'}>
            {LAYOUT_MAIN.copyright}
          </span>
        </footer>
      </div>
    );
  },
);
