export const ROUTES = {
  labwarz: {
    global: {
      root: '/',
      admin: '/admin',
    },
    event: {
      root: 'event/:event',
      admin: 'admin',
      game: {
        root: 'game',
        finished: 'game/finished',
      },
      info: 'info',
      scoreboard: 'scoreboard',
    },
  },
  login: {
    veeamId: '/veeamid',
  },
  error: {
    root: '/error',
  },
};
