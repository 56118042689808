import { callLWApi } from '@root/api/api.service';
import { endpoints } from '@root/api/endpoints';
import { AxiosResponse } from 'axios';
import { User } from '@root/domain/entities/user/user.entity';
import {
  IEvent,
  IEventFull,
  IEventScenario,
  IEventSettings,
  IIp,
  ILogIn,
} from '@root/api/api.interface';

export const labWarzApi = {
  Auth: {
    loginVeeamId: async <T>(): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Auth.LoginVeeamId, 'POST');
    },
    authToken: async <T>(data: any): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Auth.AuthToken, 'POST', data);
    },
    refreshToken: async <T>(data: any): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Auth.RefreshToken, 'POST', data);
    },
    loginByEmail: async <T>(data: any): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Auth.LoginEmail, 'POST', data);
    },
    loginAsAdmin: async <T>(data: any): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Auth.LoginAdmin, 'POST', data);
    },
  },
  Users: {
    getAllUsers: async (eventId: number): Promise<AxiosResponse<User[]>> => {
      return callLWApi(endpoints.User.GetAll(eventId), 'GET');
    },
    updateUser: async (data: any): Promise<AxiosResponse<User[]>> => {
      return callLWApi(endpoints.User.UpdateUser, 'PUT', data);
    },
    getAllUsersCompleted: async (
      eventId: number,
    ): Promise<AxiosResponse<User[]>> => {
      return callLWApi(endpoints.User.GetAllCompleted(eventId), 'GET');
    },
    getAllUsersCompletedVeeam: async (
      eventId: number,
    ): Promise<AxiosResponse<User[]>> => {
      return callLWApi(endpoints.User.GetAllCompletedVeeam(eventId), 'GET');
    },
    getCountOfAllUsers: async (): Promise<AxiosResponse<number>> => {
      return callLWApi(endpoints.User.GetAllCount, 'GET');
    },
    createNew: async (data: any): Promise<AxiosResponse<User[]>> => {
      return callLWApi(endpoints.User.Create, 'POST', data);
    },
    getUserByToken: async (): Promise<AxiosResponse> => {
      return callLWApi(endpoints.User.GetByToken, 'GET');
    },
    delete: async (data: any): Promise<AxiosResponse<User[]>> => {
      return callLWApi(endpoints.User.DeleteUser, 'DELETE', data);
    },
    getAllUsersSCore: async (): Promise<AxiosResponse> => {
      return callLWApi(endpoints.User.HideShowAllUsers, 'GET');
    },

    getInfo: async (): Promise<AxiosResponse<User>> => {
      return callLWApi(endpoints.User.GetInfo, 'GET');
    },

    searchAdmin: async <T>(filter: string): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.User.Search(filter), 'GET');
    },
  },
  Game: {
    getScenarioRoadmap: async <T>(
      scenarioId: number,
    ): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Game.GetScenarioRoadmap(scenarioId), 'GET');
    },
    startTheGame: async <T>(
      eventId: number,
      scenarioId: number,
      resultId: number,
    ): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Game.StartTheGame, 'POST', {
        eventId: eventId,
        scenarioId: scenarioId,
        resultId: resultId,
      });
    },
    loadTheGame: async (
      eventId: number,
      scenarioId: number,
      timezone: number,
    ): Promise<AxiosResponse<ILogIn>> => {
      return callLWApi(endpoints.Game.LoadTheGame, 'POST', {
        eventId: eventId,
        scenarioId: scenarioId,
        timezone: timezone,
      });
    },
    videoNextStep: async <T>(data: any): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Game.VideoNextStep, 'POST', data);
    },
    submitQuizAnswer: async <T>(data: any): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Game.QuizNextStep, 'POST', data);
    },
    screenshotNextSlide: async <T>(data: any): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Game.ScreenshotNextSlide, 'POST', data);
    },
  },

  Event: {
    getEventSettings: async (
      eventId: number,
    ): Promise<AxiosResponse<IEventSettings>> => {
      return callLWApi(endpoints.Event.GetSettings(eventId), 'GET');
    },

    updateIsScoreShown: async (
      eventId: number,
      isScoreShown: boolean,
    ): Promise<AxiosResponse<IEventSettings>> => {
      return callLWApi(endpoints.Event.UpdateIsScoreShown, 'PUT', {
        eventId: eventId,
        isScoreShown: isScoreShown,
      });
    },

    updateIsBlockedByIp: async (
      eventId: number,
      isBlockedByIp: boolean,
    ): Promise<AxiosResponse<IEventSettings>> => {
      return callLWApi(endpoints.Event.UpdateBlockedByIp, 'PUT', {
        eventId: eventId,
        isBlockedByIp: isBlockedByIp,
      });
    },

    getAllUsers: async (eventId: number): Promise<AxiosResponse<number>> => {
      return callLWApi(endpoints.Event.getAllUsers(eventId), 'GET');
    },

    getEvent: async (eventId: number): Promise<AxiosResponse<IEvent>> => {
      return callLWApi(endpoints.Event.GetEventData(eventId), 'GET');
    },

    getEventList: async (): Promise<AxiosResponse<IEvent[]>> => {
      return callLWApi(endpoints.Event.GetEventList, 'GET');
    },

    getEventListFullData: async (): Promise<AxiosResponse<IEventFull[]>> => {
      return callLWApi(endpoints.Event.GetEventListFullData, 'GET');
    },

    getEventByUrl: async (eventUrl: string): Promise<AxiosResponse<IEvent>> => {
      return callLWApi(endpoints.Event.GetEventDataByUrl(eventUrl), 'GET');
    },

    isUserAdmin: async (
      eventId: number,
      userEmail: string,
    ): Promise<AxiosResponse<boolean>> => {
      return callLWApi(endpoints.Event.isUserAdmin(eventId, userEmail), 'GET');
    },

    createEvent: async <T>(data: any): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Event.CreateEvent, 'POST', data);
    },

    updateEvent: async <T>(data: IEvent): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Event.UpdateEvent, 'PUT', data);
    },

    deleteEvent: async <T>(id: number): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Event.DeleteEvent, 'DELETE', { eventId: id });
    },

    updateAccessibility: async <T>(id: number): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Event.UpdateAccessibility, 'PUT', {
        eventId: id,
      });
    },
  },

  Scenario: {
    getScenarioList: async (
      eventId: number,
    ): Promise<AxiosResponse<IEventScenario[]>> => {
      return callLWApi(endpoints.Scenario.GetScenarioList, 'POST', {
        eventId: eventId,
      });
    },
    updateIsScenarioEnabled: async (
      eventId: number,
      scenarioId: number,
      isAvailable: boolean,
    ): Promise<AxiosResponse<IEventScenario>> => {
      return callLWApi(endpoints.Scenario.UpdateIsScenarioEnabled, 'PUT', {
        eventId: eventId,
        scenarioId: scenarioId,
        isAvailable: isAvailable,
      });
    },
  },

  Result: {
    hideUser: async <T>(
      resultId: number,
      eventId: number,
    ): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Result.hideUser, 'PUT', {
        resultId: resultId,
        eventId: eventId,
      });
    },
    resetScore: async <T>(
      resultId: number,
      eventId: number,
    ): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Result.resetScore, 'PUT', {
        resultId: resultId,
        eventId: eventId,
      });
    },
    deleteUsersData: async <T>(
      resultId: number,
      eventId: number,
    ): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Result.deleteData, 'DELETE', {
        resultId: resultId,
        eventId: eventId,
      });
    },
  },

  Ip: {
    addIp: async <T>(
      eventId: number,
      ip: string,
    ): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Ip.addIp, 'POST', {
        eventId: eventId,
        ip: ip,
      });
    },
    removeIp: async <T>(
      eventId: number,
      ip: string,
    ): Promise<AxiosResponse<T>> => {
      return callLWApi(endpoints.Ip.deleteIp, 'DELETE', {
        eventId: eventId,
        ip: ip,
      });
    },

    getIpList: async (eventId: number): Promise<AxiosResponse<IIp[]>> => {
      return callLWApi(endpoints.Ip.getIpList(eventId), 'GET');
    },
  },
};
