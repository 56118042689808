export const LEADERBOARD_PAGE = {
  leaderboard: 'Scoreboard',
  scenario: {
    simple: 'Virtual',
    hard: 'In Person',
    veeam: 'Veeam',
  },

  table: {
    name: 'Name',
    score: 'Score',
  },
};

export enum LEADERBOARD_TYPE {
  SIMPLE,
  HARD,
  VEEEAM,
}
