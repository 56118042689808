export const LOGIN = {
  labwarz: 'Welcome to ',
  scenario: {
    simple: 'Virtual',
    hard: 'In person',
  },
  note: {
    simple: 'You will need to login into your Veeam.com account',
    hard: 'Available for VeeamOn offline registrants',
  },
  button: {
    idSimple: 'virtual',
    idHard: 'onSite',
    simple: 'Start Game',
    hard: 'Start Game',
  },
  availableText: 'LabWarz Will Return Again',
};

export enum SCENARIO {
  FIRST,
  SECOND,
}
