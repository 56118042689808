import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router';
import { EventPage } from '@root/pages/EventPage/EventPage';
import { ROUTES } from '@root/routes';
import { useStore } from '@root/contextProvider/storeContext';
import { ProtectedRoute } from '@components/ProtectedRoute/ProtectedRoute';
import { InfoPage } from '@root/pages/InfoPage/InfoPage';
import { observer } from 'mobx-react-lite';
import { AdminPage } from '@root/pages/AdminPage/AdminPage';
import { LeaderboardPage } from '@root/pages/LeaderboardPage/LeaderboardPage';
import GamePage from '@root/pages/GamePage/GamePage';
import { FinishedGame } from '@components/FinishedGame/FinishedGame';
import { labWarzApi } from '@root/api/LabWarzApi';
import { Page404 } from '@root/pages/404Page/404Page';

const EventSubRoute = observer((): JSX.Element => {
  const store = useStore();

  const { event } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    if (event) {
      labWarzApi.Event.getEventByUrl(event).then((res) => {
        if (res.data) {
          store.setEvent(res.data);
        } else {
          store.setEvent(null);
          navigate(ROUTES.labwarz.global.root);
        }
      });
    }
  }, [event]);

  return (
    <>
      <Routes>
        <Route
          path={ROUTES.labwarz.event.admin}
          element={
            <ProtectedRoute
              element={<AdminPage />}
              event={store.currentEvent}
            />
          }
        />
        <Route
          path={ROUTES.labwarz.event.info}
          element={
            <ProtectedRoute
              element={<InfoPage isJustInfo={false} />}
              event={store.currentEvent}
            />
          }
        />
        <Route
          path={ROUTES.labwarz.event.scoreboard}
          element={
            <ProtectedRoute
              element={<LeaderboardPage />}
              event={store.currentEvent}
            />
          }
        />

        <Route
          path={ROUTES.labwarz.event.game.root}
          element={
            <ProtectedRoute element={<GamePage />} event={store.currentEvent} />
          }
        />
        <Route
          path={ROUTES.labwarz.event.game.finished}
          element={
            <ProtectedRoute
              element={<FinishedGame />}
              event={store.currentEvent}
            />
          }
        />
        <Route path={'/'} element={<EventPage />} />
        <Route path={'*'} element={<Page404 />} />
      </Routes>
    </>
  );
});

export default EventSubRoute;
