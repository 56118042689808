import { Buffer } from 'buffer';

export const EncryptionService = {
  encodeData: (data: string): string => {
    return Buffer.from(data, 'ascii').toString('base64');
  },

  decodeData: (data: string): string => {
    return Buffer.from(data, 'base64').toString('ascii');
  },
};
