import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import './EventAdmin.scss';
import { LayoutInner } from '@components/Layout/LayoutInner/LayoutInner';
import { EventAdminUsers } from '@components/EventAdmin/EventAdminUsers/EventAdminUsers';

import { HiUserGroup } from 'react-icons/hi';
import { IoIosSettings } from 'react-icons/io';
import { EventAdminSettings } from '@components/EventAdmin/EventAdminSettings/EventAdminSettings';
import { ADMIN_VIEW } from '@components/EventAdmin/EventAdmin.const';
import { User } from '@root/domain/entities/user/user.entity';

interface IEventAdminProps {
  userList: User[];
}

export const EventAdmin = observer(
  ({ userList }: IEventAdminProps): JSX.Element => {
    const [currentView, setCurrentView] = useState<ADMIN_VIEW>(ADMIN_VIEW.USER);
    const displayBody = {
      [ADMIN_VIEW.USER]: <EventAdminUsers userList={userList} />,
      [ADMIN_VIEW.SETTINGS]: <EventAdminSettings />,
    };
    return (
      <LayoutInner>
        <section className={'admin'}>
          <aside className={'admin__nav'}>
            <span
              className={`admin__nav__option${
                currentView === ADMIN_VIEW.USER ? ' selected' : ''
              }`}
              onClick={() => setCurrentView(ADMIN_VIEW.USER)}
            >
              <HiUserGroup size={35} />
            </span>
            <span
              className={`admin__nav__option${
                currentView === ADMIN_VIEW.SETTINGS ? ' selected' : ''
              }`}
              onClick={() => setCurrentView(ADMIN_VIEW.SETTINGS)}
            >
              <IoIosSettings size={35} />
            </span>
          </aside>
          <aside className={'admin__body'}>{displayBody[currentView]}</aside>
        </section>
      </LayoutInner>
    );
  },
);
