import React, { ReactNode } from 'react';
import { MODAL } from '@components/kit/Modal/Modal.const';
import { Button } from '@components/kit/Button/Button';

import './Modal.scss';

interface IModalProps {
  title?: string;
  onConfirm?: () => void;
  children?: ReactNode;
  onClose?: () => void;
  onConfirmLabel?: string;
  onRejectLabel?: string;
  onReject?: () => void;
}

export const Modal = ({
  onConfirm,
  onClose,
  children,
  title,
  onRejectLabel,
  onConfirmLabel,
  onReject,
}: IModalProps): JSX.Element => {
  return (
    <section className="modal-background">
      <div className="modal">
        <aside className="modal__close">
          <button onClick={onClose}>&times;</button>
        </aside>
        {title && (
          <aside className="modal__title">
            <h1>{title}</h1>
          </aside>
        )}
        <aside className="modal__body">{children}</aside>
        <aside className="modal__footer">
          {onReject && (
            <Button
              label={onRejectLabel ?? MODAL.no}
              onClick={onReject}
              className={'modal__footer__cancel'}
            />
          )}

          <Button label={onConfirmLabel ?? MODAL.yes} onClick={onConfirm} />
        </aside>
      </div>
    </section>
  );
};
