import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { User } from '@root/domain/entities/user/user.entity';
import { labWarzApi } from '@root/api/LabWarzApi';
import { Button } from '@components/kit/Button/Button';
import { Table } from '@components/kit/Table/Table';
import { Modal } from '@components/kit/Modal/Modal';
import {
  EVENT_ADMIN_TABLE_VIEW,
  EVENT_ADMIN_USERS,
  REGEX_EMAIL,
} from '@components/EventAdmin/EventAdminUsers/EventAdminUsers.const';
import close from '@images/close.svg';

import './EventAdminUsers.scss';
import { EventAdminUsersService } from '@components/EventAdmin/EventAdminUsers/EventAdminUsersService';
import { useStore } from '@root/contextProvider/storeContext';
import CustomModal from '@components/kit/Modal/CustomModal/CustomModal';
import * as XLSX from 'xlsx';
import { AiOutlineFileExcel } from 'react-icons/ai';

interface IEventAdminUsersProps {
  userList: User[];
}

export const EventAdminUsers = observer(
  ({ userList }: IEventAdminUsersProps): JSX.Element => {
    const [userListVirtual, setUserListVirtual] = useState<User[]>([]);
    const [userListOnSite, setUserListOnSite] = useState<User[]>([]);
    const [isCreateNewVisible, setIsCreateNewVisible] =
      useState<boolean>(false);
    const [isResetDisabled, setIsResetDisabled] = useState<boolean>(true);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [errorList, setErrorList] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isModalResetOpen, setIsModalResetOpen] = useState<boolean>(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState<boolean>(false);
    const [tableView, setTableView] = useState<EVENT_ADMIN_TABLE_VIEW>(
      EVENT_ADMIN_TABLE_VIEW.virtual,
    );

    const [errorModal, setErrorModal] = useState<string>('');
    const [newUser, setNewUser] = useState({
      email: '',
      name: '',
    });

    const [updatedUser, setUpdatedUser] = useState({
      email: '',
      name: '',
    });
    const [search, setSearch] = useState<string>('');

    const store = useStore();

    useEffect(() => {
      setUserListVirtual(userList.filter((user) => user.scenarioId === 0));
      setUserListOnSite(userList.filter((user) => user.scenarioId === 1));
    }, [userList]);

    const validateNewUser = (): boolean => {
      const errorListTemp: string[] = [];
      if (newUser.name.trim().length === 0) {
        errorListTemp.push(
          EVENT_ADMIN_USERS.input.name +
            EVENT_ADMIN_USERS.createNew.error.isEmpty,
        );
      }
      if (newUser.email.trim().length === 0) {
        errorListTemp.push(
          EVENT_ADMIN_USERS.input.email +
            EVENT_ADMIN_USERS.createNew.error.isEmpty,
        );
      }

      if (!REGEX_EMAIL.test(newUser.email)) {
        errorListTemp.push(EVENT_ADMIN_USERS.createNew.error.notValidEmail);
      }

      setErrorList(errorListTemp);
      return errorListTemp.length === 0;
    };

    const handleSubmit = (): void => {
      const isValid = validateNewUser();
      if (isValid) {
        labWarzApi.Users.createNew({
          email: newUser.email,
          name: newUser.name,
        })
          .then((res) => {
            setUserListVirtual(
              res.data.filter((user) => user.scenarioId === 0),
            );
            setUserListOnSite(res.data.filter((user) => user.scenarioId === 1));
            setErrorList([]);
          })
          .catch((error) => {
            setErrorMessage(error.response.data.message);
          });
        setNewUser({
          email: '',
          name: '',
        });
      }
    };

    const updateNewUserName = (name: string) => {
      setNewUser({ ...newUser, name: name });
    };
    const updateNewUserEmail = (email: string) => {
      setNewUser({ ...newUser, email: email });
    };

    const handleResetUser = () => {
      labWarzApi.Result.resetScore(selectedUser.resultId, store.currentEvent.id)
        .then((res) => {
          selectedUser.scenarioId === 0
            ? setUserListVirtual(
                EventAdminUsersService.resetScore(
                  userListVirtual,
                  selectedUser,
                ),
              )
            : setUserListOnSite(
                EventAdminUsersService.resetScore(userListOnSite, selectedUser),
              );
        })
        .catch((error) => console.error(error));
      setIsModalResetOpen(false);
    };

    const handleUpdateUser = () => {
      labWarzApi.Users.updateUser({
        idUser: selectedUser.id,
        name: updatedUser.name,
        email: updatedUser.email,
        eventId: store.currentEvent.id,
      })
        .then(() => {
          selectedUser.scenarioId === 0
            ? setUserListVirtual(
                EventAdminUsersService.updateUser(
                  userListVirtual,
                  selectedUser,
                  updatedUser,
                ),
              )
            : setUserListOnSite(
                EventAdminUsersService.updateUser(
                  userListOnSite,
                  selectedUser,
                  updatedUser,
                ),
              );
          setErrorModal('');
          setIsResetDisabled(true);
          setIsModalUpdateOpen(false);
        })
        .catch((error) => {
          console.error(error);
          setErrorModal(error.response.data.message);
        });
    };

    const handleHideUser = (e, user: User) => {
      e.stopPropagation();
      labWarzApi.Result.hideUser(user.resultId, store.currentEvent.id).then(
        () => {
          user.scenarioId === 0
            ? setUserListVirtual(
                EventAdminUsersService.hideResult(userListVirtual, user),
              )
            : setUserListOnSite(
                EventAdminUsersService.hideResult(userListOnSite, user),
              );
        },
      );
    };

    const handleSearchFilter = (val: string) => {
      setSearch(val);
    };

    const handleChangeTableView = (tableView: EVENT_ADMIN_TABLE_VIEW) => {
      setTableView(tableView);
      setIsResetDisabled(true);
      setSelectedUser(null);
    };

    const handleDeleteUser = () => {
      setIsModalDeleteOpen(false);
      setIsResetDisabled(true);
      setSelectedUser(null);
      labWarzApi.Result.deleteUsersData(
        selectedUser.resultId,
        store.currentEvent.id,
      ).then(() => {
        selectedUser.scenarioId === 0
          ? setUserListVirtual(
              EventAdminUsersService.deleteUser(userListVirtual, selectedUser),
            )
          : setUserListOnSite(
              EventAdminUsersService.deleteUser(userListOnSite, selectedUser),
            );
      });
    };

    const handleClickUpdate = (user: User) => {
      setSelectedUser(user);
      setUpdatedUser({
        email: user.email,
        name: user.name,
      });
      setIsModalUpdateOpen(true);
    };

    const handleClickReset = (user: User) => {
      setSelectedUser(user);
      setIsModalResetOpen(true);
    };

    const exportAsExcel = () => {
      const data = document.getElementById('users');
      const excelFile = XLSX.utils.table_to_book(data, {
        sheet: tableView,
      });
      XLSX.write(excelFile, {
        bookType: 'csv',
        bookSST: true,
        type: 'base64',
      });
      XLSX.writeFile(excelFile, `${tableView}_statistic.csv`);
    };

    return (
      <>
        <div className={'admin_users'}>
          {isCreateNewVisible && (
            <section className={'admin_users__create-new'}>
              <div className={'admin_users__create-new__close'}>
                <img
                  src={close}
                  alt={'CloseIcon'}
                  onClick={() => setIsCreateNewVisible(false)}
                />
              </div>
              <div className={'admin_users__create-new__form'}>
                <input
                  placeholder={EVENT_ADMIN_USERS.input.name}
                  value={newUser.name}
                  onChange={(e) => updateNewUserName(e.target.value)}
                />
                <input
                  type={'email'}
                  placeholder={EVENT_ADMIN_USERS.input.email}
                  value={newUser.email}
                  onChange={(e) => updateNewUserEmail(e.target.value)}
                />
                <Button
                  label={EVENT_ADMIN_USERS.createNew.submit}
                  onClick={handleSubmit}
                />
              </div>
              {errorList.length !== 0 && (
                <div className={'admin_users__create-new__error-list'}>
                  <ul>
                    {errorList.map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </section>
          )}
          {errorMessage && (
            <span className={'admin_users__error'}>{errorMessage}</span>
          )}
          <section className={'admin_users__search'}>
            <input
              value={search}
              placeholder={'Name or Email'}
              onChange={(e) => handleSearchFilter(e.target.value)}
            />
          </section>
          <div className={'admin_users__tab-list'}>
            <div>
              <span
                onClick={() =>
                  handleChangeTableView(EVENT_ADMIN_TABLE_VIEW.virtual)
                }
                className={
                  tableView === EVENT_ADMIN_TABLE_VIEW.virtual ? 'selected' : ''
                }
              >
                {EVENT_ADMIN_TABLE_VIEW.virtual}
              </span>
              <span
                onClick={() =>
                  handleChangeTableView(EVENT_ADMIN_TABLE_VIEW.onsite)
                }
                className={
                  tableView === EVENT_ADMIN_TABLE_VIEW.onsite ? 'selected' : ''
                }
              >
                {EVENT_ADMIN_TABLE_VIEW.onsite}
              </span>
            </div>
            <AiOutlineFileExcel onClick={exportAsExcel} size={30} />
          </div>
          <Table
            handleHideUser={handleHideUser}
            search={search}
            userList={
              tableView === EVENT_ADMIN_TABLE_VIEW.virtual
                ? userListVirtual
                : userListOnSite
            }
            updateUser={handleClickUpdate}
            resetScoreUser={handleClickReset}
          />
        </div>

        {isModalDeleteOpen && (
          <CustomModal
            confirmAction={handleDeleteUser}
            cancelAction={() => setIsModalDeleteOpen(false)}
            object={selectedUser.name}
            warningText={EVENT_ADMIN_USERS.modal.warningDelete}
          />
        )}

        {isModalResetOpen && (
          <CustomModal
            confirmAction={handleResetUser}
            cancelAction={() => setIsModalResetOpen(false)}
            object={selectedUser.name}
            warningText={EVENT_ADMIN_USERS.modal.warningReset}
          />
        )}

        {isModalUpdateOpen && (
          <Modal
            onConfirm={handleUpdateUser}
            onClose={() => setIsModalUpdateOpen(false)}
            onReject={() => setIsModalUpdateOpen(false)}
          >
            <div className={'modal-reset'}>
              <div className={'modal-reset__warning'}>
                {EVENT_ADMIN_USERS.modal.warningUpdate}
                <span className={'modal-reset__warning__name'}>
                  {selectedUser.name}
                </span>
                {' ?'}
              </div>
              <input
                placeholder={EVENT_ADMIN_USERS.input.name}
                value={updatedUser.name}
                onChange={(e) =>
                  setUpdatedUser({ ...updatedUser, name: e.target.value })
                }
              />
              <input
                type={'email'}
                placeholder={EVENT_ADMIN_USERS.input.email}
                value={updatedUser.email}
                onChange={(e) =>
                  setUpdatedUser({ ...updatedUser, email: e.target.value })
                }
              />
              {errorModal && (
                <span className={'modal-reset__error'}>{errorModal}</span>
              )}
            </div>
          </Modal>
        )}
      </>
    );
  },
);
