import React from 'react';
import { createContext, useContext } from 'react';
import { Store } from '@root/store/Store';

const ScenarioContext = createContext(null);

interface IScenarioProviderProps {
  children: JSX.Element;
}

export const StoreProvider = ({ children }: IScenarioProviderProps) => {
  const scenarioStore = new Store();

  return (
    <ScenarioContext.Provider value={scenarioStore}>
      {children}
    </ScenarioContext.Provider>
  );
};

export const useStore = () => useContext(ScenarioContext);
