export enum TEXT_ALIGN {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}
export type Column = {
  title: string;
  width: number;
  key: string;
  isVisible?: boolean;
  textAlign?: TEXT_ALIGN;
};

export type CellData = number | string | Date | string[];
