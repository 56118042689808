import { useEffect, useState } from 'react';

export const useWindowDimension = (): number[] => {
  const [dimension, setDimension] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const debouncedResizeHandler = () => {
      setDimension([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', debouncedResizeHandler);
    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, []);
  return dimension;
};
