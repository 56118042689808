import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import './EventAdminSettings.scss';
import { Button } from '@components/kit/Button/Button';
import { labWarzApi } from '@root/api/LabWarzApi';
import { MdDelete } from 'react-icons/md';
import { Toggle } from '@components/kit/Toggle/Toggle';
import { SCENARIO } from '@components/Event/Event.const';
import { useStore } from '@root/contextProvider/storeContext';
import { EVENT_ADMIN_SETTINGS } from '@components/EventAdmin/EventAdminSettings/EventAdminSettings.const';

export const EventAdminSettings = observer((): JSX.Element => {
  const [ipList, setIpList] = useState<string[]>([]);
  const [ipNew, setIpNew] = useState<string>('');

  const [isScoreShown, setIsScoreShown] = useState<boolean>(false);
  const [isBlockedByIp, setIsBlockedByIp] = useState<boolean>(false);
  const [isOnSiteOpened, setIsOnSiteOpened] = useState<boolean>(false);
  const [isVirtualOpened, setIsVirtualOpened] = useState<boolean>(false);

  const store = useStore();

  useEffect(() => {
    labWarzApi.Ip.getIpList(store.currentEvent.id).then((res) => {
      setIpList(res.data.map((ip) => ip.address));
    });
    labWarzApi.Event.getEventSettings(store.currentEvent.id).then((res) => {
      setIsScoreShown(res.data.showScore);
      setIsBlockedByIp(res.data.blockAccessByIP);
    });

    labWarzApi.Scenario.getScenarioList(store.currentEvent.id).then((res) => {
      const isFirstScenario = res.data[0].scenarioId === 1;
      setIsVirtualOpened(
        isFirstScenario ? res.data[0].isAvailable : res.data[1].isAvailable,
      );
      setIsOnSiteOpened(
        !isFirstScenario ? res.data[0].isAvailable : res.data[1].isAvailable,
      );
    });
  }, []);

  const handleAddIp = () => {
    labWarzApi.Ip.addIp(store.currentEvent.id, ipNew)
      .then((res) => setIpList([...ipList, ipNew]))
      .catch((error) => console.error(error))
      .finally(() => setIpNew(''));
  };

  const handleDeleteIp = (ip: string) => {
    labWarzApi.Ip.removeIp(store.currentEvent.id, ip)
      .then((res) => setIpList(ipList.filter((i) => i !== ip)))
      .catch((error) => console.error(error));
  };

  const handleIsScoreShown = () => {
    labWarzApi.Event.updateIsScoreShown(
      store.currentEvent.id,
      !isScoreShown,
    ).then((res) => {
      setIsScoreShown(res.data.showScore);
    });
  };

  const handleIsBlockedByIp = () => {
    labWarzApi.Event.updateIsBlockedByIp(
      store.currentEvent.id,
      !isBlockedByIp,
    ).then((res) => {
      setIsBlockedByIp(res.data.blockAccessByIP);
    });
  };

  const handleIsScenarioActive = (scenario: SCENARIO) => {
    const isVirtual = scenario === SCENARIO.FIRST;
    labWarzApi.Scenario.updateIsScenarioEnabled(
      store.currentEvent.id,
      scenario + 1,
      isVirtual ? !isVirtualOpened : !isOnSiteOpened,
    ).then((res) => {
      isVirtual
        ? setIsVirtualOpened(res.data.isAvailable)
        : setIsOnSiteOpened(res.data.isAvailable);
    });
  };

  return (
    <div className={'admin-settings'}>
      <aside className={'admin-settings__panel'}>
        <div className={'admin-settings__panel__button'}>
          <span className={'admin-settings__panel__button__option'}>
            {EVENT_ADMIN_SETTINGS.buttons.score.label}
          </span>
          <Toggle checked={isScoreShown} onChange={handleIsScoreShown} />
        </div>
        <div className={'admin-settings__panel__button'}>
          <span className={'admin-settings__panel__button__option'}>
            {EVENT_ADMIN_SETTINGS.buttons.onsite.label}
          </span>
          <Toggle checked={isBlockedByIp} onChange={handleIsBlockedByIp} />
        </div>
        <div className={'admin-settings__panel__button'}>
          <span className={'admin-settings__panel__button__option'}>
            {EVENT_ADMIN_SETTINGS.buttons.virtualOption.label}
          </span>
          <Toggle
            checked={isVirtualOpened}
            onChange={() => handleIsScenarioActive(SCENARIO.FIRST)}
          />
        </div>
        <div className={'admin-settings__panel__button'}>
          <span className={'admin-settings__panel__button__option'}>
            {EVENT_ADMIN_SETTINGS.buttons.onsiteOption.label}
          </span>
          <Toggle
            checked={isOnSiteOpened}
            onChange={() => handleIsScenarioActive(SCENARIO.SECOND)}
          />
        </div>
      </aside>
      <hr />
      <aside className={'admin-settings__ip-list'}>
        <div className={'admin-settings__ip-list__add'}>
          <input value={ipNew} onChange={(e) => setIpNew(e.target.value)} />
          <Button
            label={EVENT_ADMIN_SETTINGS.buttons.ip.add}
            onClick={handleAddIp}
          />
        </div>
        <ul className={'admin-settings__ip-list__list'}>
          {ipList.map((ip) => (
            <li className={'admin-settings__ip-list__list__item'} key={ip}>
              {ip}{' '}
              <MdDelete cursor={'pointer'} onClick={() => handleDeleteIp(ip)} />
            </li>
          ))}
        </ul>
      </aside>
    </div>
  );
});
