import * as React from 'react';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { ROUTES } from '@root/routes';
// import { GamePage } from '@root/pages/GamePage/GamePage';
import { LayoutMain } from '@components/Layout/LayoutMain/LayoutMain';
import { Page404 } from '@root/pages/404Page/404Page';
import { LoginVeeamId } from '@components/LoginVeeamId/LoginVeeamId';
import { useWindowDimension } from '@root/hooks/WindowDimension';
import {
  CONFIRMATION,
  REGISTERED_LINKS,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
} from '@root/App.const';

import './App.scss';
import { Modal } from '@components/kit/Modal/Modal';
import { useStore } from '@root/contextProvider/storeContext';
import { observer } from 'mobx-react-lite';
import { ErrorPage } from '@root/pages/ErrorPage/ErrorPage';
import GlobalPage from '@root/pages/GlobalPage/GlobalPage';
import { labWarzApi } from '@root/api/LabWarzApi';
import GlobalAdminPage from '@root/pages/GlobalAdminPage/GlobalAdminPage';
import { ADMIN_ROLE } from '@root/store/Store.const';
import { Navigate } from 'react-router-dom';
import EventSubRoute from '@components/EventSubRoute';

const App = observer((): JSX.Element => {
  const [width, height] = useWindowDimension();
  const [isWindowSmallModalOpen, setIsWindowSmallModalOpen] =
    useState<boolean>(false);
  const [isUserConfirmedWindow, setIsUserConfirmedWindow] =
    useState<boolean>(true);

  const store = useStore();
  const location = useLocation();

  useEffect(() => {
    if (store.user && store.user.role === 'ADMIN') {
      store.setIsAdmin(ADMIN_ROLE.GLOBAL);
    } else {
      store.setIsAdmin(null);
    }
    if (REGISTERED_LINKS.includes(location.pathname.split('/')[1])) {
      store.setEvent(null);
    }
  }, [location, store.user]);

  useEffect(() => {
    const authenticated = localStorage.getItem('isAuthenticated');
    if (authenticated !== undefined && authenticated !== null) {
      labWarzApi.Users.getInfo()
        .then((res) => {
          if (res.data) {
            store.setUser(res.data);
          }
        })
        .catch(() => {
          store.setEvent(null);
          store.setUser(null);
        });
    }
  }, []);

  useEffect(() => {
    const isWindowConfirmed = localStorage.getItem('windowSizeConfirmed');
    setIsUserConfirmedWindow(isWindowConfirmed === 'true');
  }, []);

  useEffect(() => {
    const ver = localStorage.getItem('aES-Fuhsdgrw-lofuit');

    if (ver && parseInt(ver) >= 1) {
      localStorage.setItem(
        'aES-Fuhsdgrw-lofuit',
        (parseInt(ver) + 1).toString(),
      );
    }

    const cleanup = () => {
      const ver = parseInt(localStorage.getItem('aES-Fuhsdgrw-lofuit'));
      if (ver > 1) {
        localStorage.setItem('aES-Fuhsdgrw-lofuit', (ver - 1).toString());
      } else if (ver === 1) {
        localStorage.removeItem('aES-Fuhsdgrw-lofuit');
      }
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  useEffect(() => {
    const scenario = localStorage.getItem('scenario');
    if (scenario) {
      store.setScenario(JSON.parse(scenario).scenarioId);
    }
  }, []);

  useEffect(() => {
    if (!isUserConfirmedWindow) {
      setIsWindowSmallModalOpen(
        width <= WINDOW_WIDTH || height <= WINDOW_HEIGHT,
      );
    } else {
      setIsWindowSmallModalOpen(false);
    }
  }, [width, height, isUserConfirmedWindow]);

  const savePreference = () => {
    setIsUserConfirmedWindow(true);
    localStorage.setItem('windowSizeConfirmed', 'true');
  };

  return (
    <>
      <LayoutMain>
        <Routes>
          <Route path={ROUTES.login.veeamId} element={<LoginVeeamId />} />

          <Route path={ROUTES.labwarz.global.root} element={<GlobalPage />} />
          <Route
            path={ROUTES.labwarz.global.admin}
            element={<GlobalAdminPage />}
          />

          <Route
            path={`${ROUTES.labwarz.event.root}/*`}
            element={<EventSubRoute />}
          />

          <Route path={'/event'} element={<Navigate to={'/'} replace />} />

          <Route path={ROUTES.error.root} element={<ErrorPage />} />
          <Route path={'*'} element={<Page404 />} />
        </Routes>
      </LayoutMain>
      {isWindowSmallModalOpen && (
        <Modal
          onConfirm={savePreference}
          onConfirmLabel={'Confirm'}
          onClose={() => setIsWindowSmallModalOpen(false)}
        >
          <span>{CONFIRMATION}</span>
        </Modal>
      )}
    </>
  );
});

export default App;
