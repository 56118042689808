import React from 'react';
import { Button } from '@components/kit/Button/Button';
import { ROUTES } from '@root/routes';
import { LayoutInner } from '@components/Layout/LayoutInner/LayoutInner';
import { useLocation, useNavigate } from 'react-router';
import { ERROR_PAGE } from '@components/Error/Error.const';

import './Error.scss';
import { useStore } from '@root/contextProvider/storeContext';

type LocationState = { statusCode: number; message: string };

export const ErrorUnhandled = (): JSX.Element => {
  const navigate = useNavigate();
  const store = useStore();
  const { state } = useLocation();
  const { statusCode, message } = state as LocationState;

  return (
    <LayoutInner>
      <section className={'error-page'}>
        <span className={'error-page__number'}>{statusCode}</span>
        <span className={'error-page__error'}>{message}</span>
        <Button
          label={ERROR_PAGE.goMain}
          onClick={() => {
            store.setEvent(null);
            navigate(ROUTES.labwarz.global.root);
          }}
        />
      </section>
    </LayoutInner>
  );
};
