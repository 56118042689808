import React, { useEffect, useState } from 'react';
import { EventAdmin } from '@components/EventAdmin/EventAdmin';
import { useNavigate } from 'react-router';
import { ROUTES } from '@root/routes';
import { useStore } from '@root/contextProvider/storeContext';
import { User } from '@root/domain/entities/user/user.entity';
import { labWarzApi } from '@root/api/LabWarzApi';
import Loading from '@components/Loading/Loading';
import { observer } from 'mobx-react-lite';

export const AdminPage = observer((): JSX.Element => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState<User[] | undefined>(undefined);
  const store = useStore();

  useEffect(() => {
    store.currentEvent &&
      labWarzApi.Users.getAllUsers(store.currentEvent.id)
        .then((res) => setUserList(res.data))
        .catch((error) => {
          console.error(error);
          navigate(
            ROUTES.labwarz.event.root.replace(':event', store.currentEvent.url),
          );
        });
  }, [store.currentEvent]);

  return !userList || !store.currentEvent ? (
    <Loading />
  ) : (
    <EventAdmin userList={userList} />
  );
});
