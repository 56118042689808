import { Mapper } from '@components/Mapper/Mapper';
import { IMap } from '@root/store/store.interface';
import React, { useEffect, useState } from 'react';
import { Map } from 'react-img-mapper';

import { labWarzApi } from '@root/api/LabWarzApi';
import { useStore } from '@root/contextProvider/storeContext';
import { observer } from 'mobx-react-lite';

import { HighLightArea } from '@components/content/Screenshot/Screenshot.style';
import { EncryptionService } from '@root/services/Encryption.service';

import { DEFAULT_MISS_CLICKS } from '@root/store/Store.const';
import './Screenshot.scss';

interface IScreenshotProps {
  src: string;
  map: IMap;
  setScore: (v: number) => void;
}

export const Screenshot = observer(
  ({ src, map, setScore }: IScreenshotProps): JSX.Element => {
    const store = useStore();
    const [mapperMap, setMapperMap] = useState<Map>();

    useEffect(() => {
      const getDecodedCoords = (coord: number[]): number[] => {
        return [coord[2], coord[3], coord[2] + coord[0], coord[1] + coord[3]];
      };

      setMapperMap({
        name: map.name,
        areas: map.areas.map((area) => ({
          shape: area.shape,
          coords: getDecodedCoords(
            area.coords.map((coord) =>
              Number(EncryptionService.decodeData(coord)),
            ),
          ),
        })),
      });
    }, [map]);

    const onSubmit = (): void => {
      labWarzApi.Game.screenshotNextSlide({
        id_task: store.state.taskId,
        id_step: store.state.stepId,
        id_slide: store.state.slideId,
        id_next_slide: store.state.body.nextImageId,
        click: EncryptionService.encodeData(store.clicks.toString()),
        eventId: store.currentEvent.id,
        scenarioId: store.getScenario(),
        resultId: store.user.resultId,
      })
        .then((res) => {
          if (res.data['outro']) {
            setScore(res.data['outro'].score);
            store.setStateOutro(res.data['outro'].src);
          } else {
            localStorage.removeItem('missClicks');
            store.resetClicks();
            store.setState(res.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const handleClick = (): void => {
      localStorage.setItem(
        'missClicks',
        (DEFAULT_MISS_CLICKS - store.clicks + 1).toString(),
      );
      if (store.clicks > 0) {
        store.minusClick();
      }
    };

    return (
      <section className={'screenshot'}>
        {mapperMap && src && store.roadmap ? (
          <>
            <span className={'screenshot__step-title'}>
              {
                store.roadmap.tasks[store.state.taskId]?.steps[
                  store.state.stepId
                ]
              }
            </span>
            <span className={'screenshot__step-description'}>
              {store.state.body.description}
            </span>
            <aside className={'screenshot__image'}>
              <Mapper
                src={src}
                map={mapperMap}
                onSubmit={onSubmit}
                onClick={handleClick}
              />
              {mapperMap &&
                store.clicks <= 0 &&
                mapperMap.areas.map((area, i) => (
                  <HighLightArea key={i} areaCoords={area.coords} />
                ))}
            </aside>
          </>
        ) : null}
      </section>
    );
  },
);
