import React from 'react';
import { User } from '@root/domain/entities/user/user.entity';
import { TABLE } from '@components/kit/Table/Table.const';

import './Table.scss';
import { FiEdit2 } from 'react-icons/fi';
import { GrPowerReset } from 'react-icons/gr';
import { AiOutlineDelete } from 'react-icons/ai';
import { Toggle } from '@components/kit/Toggle/Toggle';
import { DateService } from '@root/services/Date.service';

interface ITableProps {
  search: string;
  userList: User[];
  selectedUser?: User;
  handleHideUser: (e, email: User) => void;
  handleSelectUser?: (user: User) => void;
  updateUser: (user: User) => void;
  resetScoreUser: (user: User) => void;
}

export const Table = ({
  search,
  userList,
  handleHideUser,
  updateUser,
  resetScoreUser,
}: ITableProps): JSX.Element => {
  return (
    <table className={'table'} id={'users'}>
      <thead>
        <tr>
          <th>{TABLE.header.number}</th>
          <th>{TABLE.header.name}</th>
          <th>{TABLE.header.email}</th>
          <th>{TABLE.header.score}</th>
          <th>{TABLE.header.hasFinished}</th>
          <th>{TABLE.header.isShown}</th>
          <th>{TABLE.header.finishedAt}</th>
          <th>{TABLE.header.actionList}</th>
        </tr>
      </thead>
      <tbody>
        {userList
          .filter(
            (user) =>
              user.name.toLowerCase().includes(search.toLowerCase()) ||
              user.email.toLowerCase().includes(search.toLowerCase()),
          )
          .sort(
            (userA, userB) =>
              userB.score - userA.score ||
              userA.name.localeCompare(userB.name) ||
              userA.email.localeCompare(userB.email),
          )
          .map((user, i) => (
            <tr key={user.id} className={`table__row`}>
              <th>{i + 1}</th>
              <th>{user.name}</th>
              <th>{user.email}</th>
              <th>{user.score}</th>
              <th>
                {user.hasCompleted ? TABLE.completed.yes : TABLE.completed.no}
              </th>
              <th>
                <Toggle
                  checked={user.resultIsShown}
                  onChange={(e) => handleHideUser(e, user)}
                />
              </th>
              <th>
                {DateService.calculateTimeOfEventTimezone(
                  new Date(user.finishedAt),
                ).toLocaleString('en-US')}
              </th>
              <th className={'table__row__actions'}>
                <GrPowerReset size={20} onClick={() => resetScoreUser(user)} />
                <FiEdit2
                  size={20}
                  color={'orange'}
                  onClick={() => updateUser(user)}
                />
              </th>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
