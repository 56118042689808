import { User } from '@root/domain/entities/user/user.entity';

export const EventAdminUsersService = {
  resetScore: (list: User[], user: User): User[] => {
    return list.map((u) =>
      u.id === user.id
        ? {
            ...user,
            score: 0,
          }
        : u,
    );
  },
  hideResult: (list: User[], user: User): User[] => {
    return list.map((u) =>
      u.id === user.id ? { ...user, resultIsShown: !user.resultIsShown } : u,
    );
  },
  updateUser: (
    list: User[],
    user: User,
    userUpdated: { name: string; email: string },
  ): User[] => {
    return list.map((u) =>
      u.id === user.id
        ? { ...user, name: userUpdated.name, email: userUpdated.email }
        : u,
    );
  },
  deleteUser: (list: User[], user: User): User[] => {
    return list.filter((u) => u.id !== user.id);
  },
};
