import {
  Column,
  TEXT_ALIGN,
} from '@components/kit/SimpleTable/SimpleTable.type';

export const COLUMN_LIST: Column[] = [
  {
    title: 'Title',
    width: 10,
    key: 'title',
    textAlign: TEXT_ALIGN.LEFT,
  },
  {
    title: 'Url',
    width: 10,
    key: 'url',
    textAlign: TEXT_ALIGN.LEFT,
  },
  {
    title: 'Start Date',
    width: 10,
    key: 'startDate',
    textAlign: TEXT_ALIGN.LEFT,
  },
  {
    title: 'End Date',
    width: 10,
    key: 'endDate',
    textAlign: TEXT_ALIGN.LEFT,
  },
  {
    title: 'Time Zone',
    width: 5,
    key: 'timeZone',
    textAlign: TEXT_ALIGN.CENTER,
  },
  {
    title: 'Event Admins',
    width: 30,
    key: 'eventAdmins',
    textAlign: TEXT_ALIGN.CENTER,
  },
  {
    title: 'Accessible',
    width: 10,
    key: 'isActive',
    textAlign: TEXT_ALIGN.CENTER,
  },
  {
    title: '',
    width: 5,
    key: 'actions',
    textAlign: TEXT_ALIGN.CENTER,
  },
];
