import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
    0% {
      background-color: transparent;
    }
    50% {
      background-color: #08FF00;
    }
    100% {
      background-color: transparent;
    }`;
interface Props {
  areaCoords: number[];
}
export const HighLightArea = styled.div<Pick<Props, 'areaCoords'>>`
  position: absolute;
  top: ${(p) => p.areaCoords[1]}px;
  left: ${(p) => p.areaCoords[0]}px;
  width: ${(p) => p.areaCoords[2] - p.areaCoords[0]}px;
  height: ${(p) => p.areaCoords[3] - p.areaCoords[1]}px;
  opacity: 40%;
  z-index: 1;
  border-radius: 5px;
  pointer-events: none;
  animation: 2s ${pulse} infinite;
`;
