let baseUrl = '';
if (process.env.NODE_ENV !== 'production') {
  // baseUrl = 'http://localhost:7123/api';
  baseUrl = 'https://labwarz.demo.cis.local/api';
} else {
  baseUrl = '/api';
}
export const endpoints = {
  Auth: {
    LoginEmail: `${baseUrl}/auth/loginemail`,
    LoginAdmin: `${baseUrl}/auth/loginadmin`,
    LoginVeeamId: `${baseUrl}/auth/login`,
    AuthToken: `${baseUrl}/auth/authtoken`,
    RefreshToken: `${baseUrl}/auth/refreshtoken`,
  },
  User: {
    UpdateUser: `${baseUrl}/users`,
    GetInfo: `${baseUrl}/users`,
    Search: (filter: string): string => `${baseUrl}/users/search/${filter}`,
    GetAll: (eventId: number): string => `${baseUrl}/users/${eventId}`,
    GetAllCompleted: (eventId: number): string =>
      `${baseUrl}/users/completed/${eventId}`,
    GetAllCompletedVeeam: (eventId: number): string =>
      `${baseUrl}/users/completedVeeam/${eventId}`,
    GetAllCount: `${baseUrl}/users/count`,
    Create: `${baseUrl}/users/createAdmin`,
    GetByToken: `${baseUrl}/users/token`,
    DeleteUser: `${baseUrl}/users`,
    HideShowAllUsers: `${baseUrl}/users/hidescoretable`,
    GetAdminName: `${baseUrl}/users/adminname`,
  },
  Game: {
    GetScenarioRoadmap: (scenarioId: number): string =>
      `${baseUrl}/game/scenario/${scenarioId}`,
    LoadTheGame: `${baseUrl}/game`,
    StartTheGame: `${baseUrl}/game/start`,
    VideoNextStep: `${baseUrl}/game/nextstepvideo`,
    QuizNextStep: `${baseUrl}/game/nextstepquiz`,
    ScreenshotNextSlide: `${baseUrl}/game/nextslidescreenshot`,
  },
  Event: {
    GetSettings: (eventId: number): string =>
      `${baseUrl}/event-settings/${eventId}`,
    UpdateIsScoreShown: `${baseUrl}/event-settings/isscoreshown`,
    UpdateBlockedByIp: `${baseUrl}/event-settings/isblockedbyip`,
    getAllUsers: (eventId: number): string =>
      `${baseUrl}/event/count/${eventId}`,
    GetEventData: (eventId: number): string => `${baseUrl}/event/${eventId}`,
    GetEventDataByUrl: (eventUrl: string): string =>
      `${baseUrl}/event/name/${eventUrl}`,
    GetEventList: `${baseUrl}/event/all`,
    GetEventListFullData: `${baseUrl}/event/full`,
    CreateEvent: `${baseUrl}/event`,
    DeleteEvent: `${baseUrl}/event`,
    UpdateEvent: `${baseUrl}/event`,
    UpdateAccessibility: `${baseUrl}/event/active`,
    isUserAdmin: (eventId: number, userEmail: string): string =>
      `${baseUrl}/event/admin/${eventId}/${userEmail}`,
  },
  Scenario: {
    GetScenarioList: `${baseUrl}/event-scenario`,
    UpdateIsScenarioEnabled: `${baseUrl}/event-scenario`,
  },
  Result: {
    hideUser: `${baseUrl}/result/hide`,
    resetScore: `${baseUrl}/result/reset`,
    deleteData: `${baseUrl}/result`,
  },
  Ip: {
    addIp: `${baseUrl}/event-settings/ip`,
    deleteIp: `${baseUrl}/event-settings/ip`,
    getIpList: (eventId: number): string =>
      `${baseUrl}/event-settings/ip/${eventId}`,
  },
};
