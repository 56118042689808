import React from 'react';
import './Button.scss';
interface IButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  id?: string;
  className?: string;
  isLowercase?: boolean;
}

export const Button = ({
  label,
  onClick,
  disabled,
  id,
  className,
  isLowercase,
}: IButtonProps): JSX.Element => {
  return (
    <button
      type={'button'}
      id={id}
      onClick={onClick}
      className={`button${disabled ? ' disabled' : ''} ${
        className ? className : ''
      }`}
    >
      <span className={'button__text'}>
        {isLowercase ? label.toLowerCase() : label.toUpperCase()}
      </span>
    </button>
  );
};
