import React from 'react';
import { Column } from '@components/kit/SimpleTable/SimpleTable.type';
import { IEventFull } from '@root/api/api.interface';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { FiEdit2 } from 'react-icons/fi';

import './SimpleTable.scss';
import { Toggle } from '@components/kit/Toggle/Toggle';
import { DateService } from '@root/services/Date.service';

interface ISimpleTableProps {
  columnList: Column[];
  data: IEventFull[];
  isActionBlocked: boolean;
  onUpdate?: (e: IEventFull) => void;
  onDelete: (event: IEventFull) => void;
  onUpdateAccessibility: (id: number) => void;
}

export const SimpleTable = ({
  columnList,
  data,
  isActionBlocked,
  onUpdate,
  onDelete,
  onUpdateAccessibility,
}: ISimpleTableProps): JSX.Element => {
  return (
    <table className={'simple-table'}>
      <thead>
        <tr>
          {columnList.map((column: Column) => (
            <th
              key={column.key}
              style={{
                width: `${column.width}%`,
                textAlign: column.textAlign,
              }}
            >
              {column.title}
            </th>
          ))}
        </tr>
        <tr></tr>
      </thead>
      <tbody>
        {data &&
          data.length > 0 &&
          data
            .sort((a, b) =>
              a.startDate > b.startDate
                ? a.name > b.name
                  ? 1
                  : -1
                : a.name > b.name
                ? 1
                : -1,
            )
            .map((row: IEventFull) => (
              <tr key={row.url}>
                <td>{row.name}</td>
                <td>{row.url}</td>
                <td>
                  {DateService.calculateTimeOfEventTimezone(
                    new Date(row.startDate),
                  ).toLocaleString('en-US')}
                </td>
                <td>
                  {DateService.calculateTimeOfEventTimezone(
                    new Date(row.endDate),
                  ).toLocaleString('en-US')}
                </td>
                <td className={'simple-table__number-row'}>{row.timeZone}</td>
                <td className={'simple-table__number-row'}>
                  {row.eventAdminList &&
                    row.eventAdminList.map((admin, i) => (
                      <span key={admin}>
                        {admin}
                        {i === row.eventAdminList.length - 1 ? '' : ','}
                      </span>
                    ))}
                </td>
                <td className={'simple-table__number-row'}>
                  <Toggle
                    checked={row.isActive}
                    onChange={() => onUpdateAccessibility(row.id)}
                  />
                </td>
                <td className={'simple-table__action-list'}>
                  <FiEdit2
                    className={'simple-table__action-list__update'}
                    size={18}
                    onClick={() => onUpdate(row)}
                  />
                  <RiDeleteBin7Line
                    className={'simple-table__action-list__delete'}
                    size={18}
                    onClick={() => (isActionBlocked ? null : onDelete(row))}
                  />
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  );
};
