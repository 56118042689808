import React, { useEffect } from 'react';
import { ScenarioRoadmap } from '@components/ScenarioRoadmap/ScenarioRoadmap';
import { ScenarioTaskContent } from '@components/ScenarioTaskContent/ScenarioTaskContent';

import './Game.scss';
import { LayoutInner } from '@components/Layout/LayoutInner/LayoutInner';
import { labWarzApi } from '@root/api/LabWarzApi';
import { ROUTES } from '@root/routes';
import { useStore } from '@root/contextProvider/storeContext';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import Loading from '@components/Loading/Loading';

export const Game = observer((): JSX.Element => {
  const store = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    const ver = localStorage.getItem('aES-Fuhsdgrw-lofuit');
    if (ver && parseInt(ver) > 1) {
      store.setEvent(null);
      navigate(ROUTES.labwarz.global.root);
    } else {
      localStorage.setItem('aES-Fuhsdgrw-lofuit', '1');
    }
  }, []);

  useEffect(() => {
    store.currentEvent &&
      labWarzApi.Game.loadTheGame(
        store.currentEvent.id,
        store.scenario,
        new Date().getTimezoneOffset(),
      )
        .then((res) => {
          if (!res.data.state) {
            navigate('/event/' + store.currentEvent.url + '/game/finished', {
              state: { score: res.data },
              replace: true,
            });
            localStorage.removeItem('missClicks');
          } else {
            if (res.data.state.taskId === -1) {
              localStorage.removeItem('missClicks');
            } else {
              const missClicks = localStorage.getItem('missClicks');
              if (missClicks) {
                store.setClicks(missClicks);
              }
            }
            if (res.data.resultId) {
              store.setUser({
                ...store.user,
                resultId: res.data.resultId,
              });
            }
            store.setState(res.data.state);
          }
        })
        .catch((error) => {
          localStorage.removeItem('missClicks');
          navigate(ROUTES.error.root, {
            state: error.response.data,
          });
        });
  }, [store.currentEvent]);

  return store.state && store.currentEvent ? (
    <LayoutInner>
      <section className={'scenario-player'}>
        <aside className={`scenario-player__sidebar`}>
          <ScenarioRoadmap />
        </aside>
        <aside className={`scenario-player__main`}>
          <ScenarioTaskContent />
        </aside>
      </section>
    </LayoutInner>
  ) : (
    <Loading />
  );
});
