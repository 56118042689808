export const TABLE = {
  header: {
    number: '#',
    name: 'Name',
    email: 'Email',
    score: 'Score',
    isShown: 'Is Shown',
    finishedAt: 'Finished At',
    hasFinished: 'Completed',
    actionList: 'Actions',
  },
  completed: {
    yes: 'YES',
    no: 'NO',
  },
};
