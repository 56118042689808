import { SCENARIO_PLAYER } from '@components/Game/Game.const';
import { ProgressBar } from '@components/kit/Progress/Progress';
import { useStore } from '@root/contextProvider/storeContext';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { CONTENT_HEADER_CONST } from '@components/ContentHeader/ContentHeader.const';
import ReactTooltip from 'react-tooltip';

import './ContentHeader.scss';

export const ContentHeader = observer((): JSX.Element => {
  const store = useStore();
  const [timer, setTimer] = useState<number>(0);
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    if (store.state) {
      const roundedTime = Math.round(store.time);
      if (store.state.isLoad) {
        setTimer(roundedTime);
        setTime(roundedTime);
        store.setIsLoad();
      } else if (store.state.slideId <= 0) {
        setTimer(roundedTime);
        setTime(roundedTime);
      }
    }
  }, [store.state]);

  useEffect(() => {
    if (timer > 0) {
      const timer_id = setInterval(() => {
        setTimer(timer - 1);
      }, 975);

      return () => {
        clearInterval(timer_id);
      };
    } else {
      store.setTime(0);
    }
  }, [timer]);

  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
  };
  const toTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  };

  return store.state && store.roadmap ? (
    <section className={'content-header'}>
      <ProgressBar
        bgColor={'#00b336'}
        progress={time > 0 ? (1 - timer / time) * 100 : 0}
        height={10}
      />
      <aside className={'content-header__info'}>
        <span className={'content-header__info__title'}>
          {store.state.taskId === 1000
            ? store.roadmap.outro
            : store.state.taskId >= 0
            ? store.roadmap.tasks[store.state.taskId]?.title
            : store.roadmap.intro}
        </span>
        <div className={'content-header__info__timer-tooltip'}>
          {store.clicks <= 2 && store.state.body.tip && (
            <>
              <span
                data-tip
                data-for={'tip'}
                className={'content-header__info__timer-tooltip__hint'}
              >
                {CONTENT_HEADER_CONST.hint}
              </span>
              <ReactTooltip
                id="tip"
                place="left"
                effect="solid"
                backgroundColor={'#00b336'}
              >
                {store.state.body.tip}
              </ReactTooltip>
            </>
          )}

          <span
            className={`content-header__info__timer-tooltip__timer ${
              timer === 0 ? 'frozen' : ''
            }`}
          >
            {store.state.type !== 'video' && (
              <>
                <span>
                  {timer === 0 ? SCENARIO_PLAYER.frozen : SCENARIO_PLAYER.left}
                </span>
                <span
                  className={'content-header__info__timer-tooltip__timer__time'}
                >
                  {toTime(timer)}
                </span>
              </>
            )}
          </span>
        </div>
      </aside>
    </section>
  ) : null;
});
