import { makeAutoObservable } from 'mobx';
import { IScenarioRoadmap, IState } from '@root/store/store.interface';
import { ADMIN_ROLE, DEFAULT_MISS_CLICKS } from '@root/store/Store.const';
import { User } from '@root/domain/entities/user/user.entity';
import { SCENARIO } from '@components/Event/Event.const';
import { IEvent } from '@root/api/api.interface';

export class Store {
  user: User | null = null;
  state: IState | null = null;
  roadmap: IScenarioRoadmap | null = null;
  clicks: number = DEFAULT_MISS_CLICKS;
  time: number | null = 0;
  isLoad: boolean;
  scenario: SCENARIO | null;
  isLoading: boolean;
  showUsersScore: boolean;
  currentEvent: IEvent | null | undefined = undefined;
  isAdmin: ADMIN_ROLE | null = null;
  isScenario1Active: boolean;
  isScenario2Active: boolean;

  constructor() {
    this.showUsersScore = false;
    this.isLoading = false;
    this.isScenario1Active = false;
    this.isScenario2Active = false;
    makeAutoObservable(this);
  }

  setState(state: IState): void {
    this.state = state;
    if (state['isLoad']) {
      this.setIsLoad();
    }
    if (state.time) {
      this.setTime(state.time);
    } else if (state.slideId === -1) {
      this.setTime(0);
    }
  }

  setStateOutro(src: string): void {
    this.state = {
      taskId: 1000,
      type: 'video',
      stepId: -1,
      slideId: -1,
      time: 0,
      body: { src: src },
    };
  }

  setTime(time: number): void {
    this.time = time;
  }

  setRoadmap(roadmap: IScenarioRoadmap): void {
    this.roadmap = roadmap;
  }

  resetClicks(): void {
    this.clicks = DEFAULT_MISS_CLICKS;
  }

  minusClick(): void {
    this.clicks--;
  }

  setUser(user: User): void {
    this.user = user;
  }

  setIsLoad(): void {
    this.isLoad = !this.isLoad;
  }

  setShowUsersScore(value: boolean): void {
    this.showUsersScore = value;
  }

  getShowUsersScore(): boolean {
    return this.showUsersScore;
  }

  setClicks(missClicks: string): void {
    this.clicks = DEFAULT_MISS_CLICKS - parseInt(missClicks);
  }

  setScenario(scenario: SCENARIO): void {
    this.scenario = scenario;
  }
  getScenario(): SCENARIO {
    return this.scenario;
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  getIsLoading(): boolean {
    return this.isLoading;
  }

  setEvent(event: IEvent): void {
    this.currentEvent = event;
  }

  getEvent(): IEvent {
    return this.currentEvent;
  }

  setIsAdmin(value: ADMIN_ROLE | null): void {
    this.isAdmin = value;
  }

  setFirstScenarioAvailability(val: boolean): void {
    this.isScenario1Active = val;
  }

  setSecondScenarioAvailability(val: boolean): void {
    this.isScenario2Active = val;
  }
}
