import CSS from 'csstype';
export const parentDiv = (height: number): CSS.Properties => {
  return {
    height: height + 'px',
    width: '100%',
    backgroundColor: '#CDCDCD',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    overflow: 'hidden',
  };
};

export const childDiv = (progress: number, bgColor: string): CSS.Properties => {
  return {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgColor,
    zIndex: '-1',
  };
};
