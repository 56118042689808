import { INFO } from '@components/Info/Info.const';
import { LayoutInner } from '@components/Layout/LayoutInner/LayoutInner';
import { Button } from '@components/kit/Button/Button';
import React from 'react';
import { useNavigate } from 'react-router';

import { useStore } from '@root/contextProvider/storeContext';
import './Info.scss';

interface IInfoProps {
  isJustInfo?: boolean;
}
export const Info = ({ isJustInfo }: IInfoProps): JSX.Element => {
  const navigate = useNavigate();
  const store = useStore();

  const handleConfirm = () => {
    navigate('/event/' + store.currentEvent.url + '/game');
  };

  return (
    <LayoutInner>
      <div className={'info'}>
        <h1 className={'info__welcome'}>{INFO.welcome}</h1>
        <>
          <p>{INFO.audio}</p>
          <p>{INFO.scoring}</p>
          <p>{INFO.controls}</p>
        </>
        <>{INFO.quiz}</>
        <>
          {INFO.simulation.main}
          <ul>
            <li>{INFO.simulation.hint}</li>
            <li>{INFO.simulation.highlight}</li>
          </ul>
        </>
        <p>{INFO.finish}</p>
        {!isJustInfo && (
          <Button
            label={INFO.confirm}
            onClick={handleConfirm}
            className={'info__confirm'}
          />
        )}
      </div>
    </LayoutInner>
  );
};
