import React, { useEffect, useState } from 'react';
import './EventCard.scss';
import { IEvent } from '@root/api/api.interface';
import { EVENT_STATUS } from '@components/EventCard/EventCard.status';
import { Link } from 'react-router-dom';
import { useStore } from '@root/contextProvider/storeContext';
import { DateService } from '@root/services/Date.service';

interface IEventCardProps {
  event: IEvent;
}

const EventCard = ({ event }: IEventCardProps): JSX.Element => {
  const [status, setStatus] = useState<EVENT_STATUS | null>(null);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const store = useStore();
  useEffect(() => {
    const currentDate = new Date();
    const startD = new Date(event.startDate);
    const endD = new Date(event.endDate);
    const sD = DateService.calculateTimeOfCurrentTimezone(
      startD,
      event.timeZone,
    );
    const eD = DateService.calculateTimeOfCurrentTimezone(endD, event.timeZone);
    setStartDate(sD);
    setEndDate(eD);
    if (sD > currentDate) {
      setStatus(EVENT_STATUS.UPCOMING);
    } else if (sD < currentDate && eD > currentDate) {
      setStatus(EVENT_STATUS.ONGOING);
    } else {
      setStatus(EVENT_STATUS.PASSED);
    }
  }, [event]);

  const setEventUrl = (event: IEvent) => {
    store.setEvent(event);
  };

  return status && event ? (
    <Link
      className={'event-card'}
      to={`event/${event.url}`}
      onClick={() => setEventUrl(event)}
    >
      <div className={`event-card__content ${status.toLowerCase()}`}>
        {status !== EVENT_STATUS.PASSED && (
          <span
            className={`event-card__content__status ${status.toLowerCase()}`}
          >
            {status}
          </span>
        )}

        <div className={'event-card__content__info'}>
          <span className={'event-card__content__info__name'}>
            {event.name}
          </span>
          <span className={'event-card__content__info__date'}>
            {`${startDate.toLocaleDateString(
              'en-US',
            )} - ${endDate.toLocaleDateString('en-US')}`}
          </span>
        </div>
      </div>
    </Link>
  ) : null;
};

export default EventCard;
