import React from 'react';
import { ContentHeader } from '@components/ContentHeader/ContentHeader';
import { ContentBody } from '@components/ContentBody/ContentBody';

import './ScenarioTaskContent.scss';

export const ScenarioTaskContent = (): JSX.Element => {
  return (
    <section className={'scenario-task-content'}>
      <aside className={'scenario-task-content__header'}>
        <ContentHeader />
      </aside>
      <aside className={'scenario-task-content__body'}>
        <ContentBody />
      </aside>
    </section>
  );
};
