export const EVENT_ADMIN_SETTINGS = {
  buttons: {
    score: {
      showScore: 'Show Score',
      hideScore: 'Hide Score',
      label: 'Score is shown in scoreboard',
    },
    onsite: {
      block: 'Block Scenarios by Ip',
      unblock: 'Unblock Scenarios by Ip',
      label: 'Access to scenarios is blocked by IP',
    },
    onsiteOption: {
      block: 'Close In Person Scenario',
      unblock: 'Open In Person Scenario',
      label: 'In Person Scenario is active',
    },
    virtualOption: {
      block: 'Close Virtual Scenario',
      unblock: 'Open Virtual Scenario',
      label: 'Virtual Scenario is active',
    },
    ip: {
      add: 'Add Ip address',
    },
  },
};
