export const EVENT_ADMIN_USERS = {
  buttonPanel: {
    new: 'Add new',
    update: 'Update user',
    reset: 'Reset score',
    showScore: 'Show Score',
    hideScore: 'Hide Score',
    delete: 'Delete',
  },
  input: {
    name: 'Name',
    email: 'Email',
  },
  createNew: {
    submit: 'Create new User',
    error: {
      isEmpty: " can't be empty",
      notValidEmail: 'Email should be in correct form',
    },
  },
  modal: {
    warningUpdate: 'Would you like to update info about ',
    warningReset: 'Would you like to reset score of ',
    warningSmallReset: 'This action cannot be undone.',
    warningDelete: 'Would you like to delete user ',
    warningSmallDelete: 'This action cannot be undone.',
  },
};

export const REGEX_EMAIL =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export enum EVENT_ADMIN_TABLE_VIEW {
  onsite = 'In Person',
  virtual = 'Virtual',
}
