import React from 'react';
import { childDiv, parentDiv } from '@components/kit/Progress/Progress.style';

interface IProgressBarProps {
  bgColor: string;
  progress: number;
  height: number;
}

export const ProgressBar = ({
  bgColor,
  progress,
  height,
}: IProgressBarProps): JSX.Element => {
  return (
    <div style={parentDiv(height)}>
      <div style={childDiv(progress, bgColor)} />
    </div>
  );
};
