import axios, { AxiosResponse, Method } from 'axios';

export const callLWApi = async <T>(
  apiEndpoint: string,
  method: Method,
  body?: any,
  contentType = 'application/json',
): Promise<AxiosResponse<T>> => {
  const accessToken = localStorage.getItem('isAuthenticated');
  const headers = {
    Authorization:
      accessToken && `Bearer ${JSON.parse(accessToken)['access_token']}`,
    'Content-Type': contentType,
  };
  return axios.request<T>({
    method,
    url: apiEndpoint,
    headers: headers,
    data: body,
  });
};
