import React from 'react';
import './Toggle.scss';
interface IToggleProps {
  checked: boolean;
  onChange?: (e?) => void;
}
export const Toggle = ({ checked, onChange }: IToggleProps): JSX.Element => {
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="switch" />
    </label>
  );
};
