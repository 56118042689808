import React from 'react';
import { Button } from '@components/kit/Button/Button';
import { useNavigate } from 'react-router';
import { ROUTES } from '@root/routes';
import { PAGE_NOT_FOUND_CONST } from '@components/404/404.const';

import './404.scss';
import { LayoutInner } from '@components/Layout/LayoutInner/LayoutInner';
import { useStore } from '@root/contextProvider/storeContext';

export const PageNotFound = (): JSX.Element => {
  const navigate = useNavigate();
  const store = useStore();
  return (
    <LayoutInner>
      <section className={'page-not-found'}>
        <span className={'page-not-found__404'}>
          {PAGE_NOT_FOUND_CONST['404']}
        </span>
        <span className={'page-not-found__error'}>
          {PAGE_NOT_FOUND_CONST.notFound}
        </span>
        <Button
          label={PAGE_NOT_FOUND_CONST.goMain}
          onClick={() => {
            store.setEvent(null);
            navigate(ROUTES.labwarz.global.root);
          }}
        />
      </section>
    </LayoutInner>
  );
};
