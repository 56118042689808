import React, { useEffect, useState } from 'react';
import { LayoutInner } from '@components/Layout/LayoutInner/LayoutInner';
import { SimpleTable } from '@components/kit/SimpleTable/SimpleTable';
import { COLUMN_LIST } from '@components/GlobalAdmin/GlobalAdmin.table-settings';
import { Button } from '@components/kit/Button/Button';
import {
  DEFAULT_EVENT_STATE,
  DEFAULT_TIMEZONE,
  ERROR_FIELD,
  ERROR_MESSAGE,
  Event,
  GLOBAL_ADMIN,
  REGEX_NAME,
} from '@components/GlobalAdmin/GlobalAdmin.const';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './GlobalAdmin.scss';
import { IEventFull } from '@root/api/api.interface';
import { labWarzApi } from '@root/api/LabWarzApi';
import Loading from '@components/Loading/Loading';
import CustomModal from '@components/kit/Modal/CustomModal/CustomModal';
import { PeoplePicker } from '../kit/PeoplePicker/PeoplePicker';
import { OptionType } from '@components/kit/PeoplePicker/PeoplePicker.type';
import { DateService } from '@root/services/Date.service';
import TimezoneSelect, { ITimezone } from 'react-timezone-select';

interface IGlobalAdminProps {
  data: IEventFull[];
  refreshData: () => Promise<unknown>;
}

export const GlobalAdmin = ({
  data,
  refreshData,
}: IGlobalAdminProps): JSX.Element => {
  const [dataE, setDataE] = useState<IEventFull[]>([]);
  const [event, setEvent] = useState<Event>(DEFAULT_EVENT_STATE);
  const [selectedEvent, setSelectedEvent] = useState<IEventFull | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [buttonLabel, setButtonLabel] = useState<string>(
    GLOBAL_ADMIN.buttons.add,
  );
  const [selectedOptionList, setSelectedOptionList] = useState<OptionType[]>(
    [],
  );
  const [errorList, setErrorList] = useState<ERROR_FIELD[]>([]);
  const [selectedTimezone, setSelectedTimezone] =
    useState<ITimezone>(DEFAULT_TIMEZONE);

  useEffect(() => {
    setDataE(data);
  }, [data]);

  const handleSubmitEvent = () => {
    if (isValid()) {
      const localDateEvent = {
        ...event,
        startDate: new Date(
          event.startDate.getTime() -
            event.startDate.getTimezoneOffset() * 60000,
        ),
        endDate: new Date(
          event.endDate.getTime() - event.endDate.getTimezoneOffset() * 60000,
        ),
        timeZone: selectedTimezone['offset'].toString(),
        eventAdminList: selectedOptionList.map((admin) => admin.value),
      };
      if (buttonLabel === GLOBAL_ADMIN.buttons.update) {
        labWarzApi.Event.updateEvent({
          ...localDateEvent,
          id: selectedEvent.id,
        })
          .then()
          .catch((error) => console.error(error))
          .finally(() => {
            refreshData();
            setSelectedTimezone(DEFAULT_TIMEZONE);
            setSelectedOptionList([]);
            setButtonLabel(GLOBAL_ADMIN.buttons.add);
            setEvent(DEFAULT_EVENT_STATE);
          });
      } else {
        labWarzApi.Event.createEvent(localDateEvent)
          .then()
          .catch((error) => console.error(error))
          .finally(() => {
            refreshData();
            setSelectedTimezone(DEFAULT_TIMEZONE);
            setSelectedOptionList([]);
            setEvent(DEFAULT_EVENT_STATE);
          });
      }
    }
  };

  const handleOpenModal = (row: IEventFull) => {
    setSelectedEvent(row);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateExistingEvent = (row: IEventFull) => {
    setErrorList([]);
    setSelectedTimezone(DEFAULT_TIMEZONE);
    setSelectedEvent(row);
    setButtonLabel(GLOBAL_ADMIN.buttons.update);
    setSelectedOptionList(
      row.eventAdminList.map((admin) => {
        return { label: admin, value: admin };
      }),
    );
    setEvent({
      name: row.name,
      startDate: DateService.calculateTimeOfEventTimezone(
        new Date(row.startDate),
      ),
      endDate: DateService.calculateTimeOfEventTimezone(new Date(row.endDate)),
      eventAdminList: row.eventAdminList,
      url: row.url,
      timeZone: DEFAULT_TIMEZONE['offset'],
    });
  };

  const isValid = (): boolean => {
    setErrorList([]);
    const error: ERROR_FIELD[] = [];
    if (event.name.length > 15) {
      error.push(ERROR_FIELD.TITLE);
    }

    if (event.name.length === 0) {
      error.push(ERROR_FIELD.REQUIRED);
    }

    if (selectedOptionList.length === 0) {
      error.push(ERROR_FIELD.ADMIN);
    }

    setErrorList(error);

    return error.length === 0;
  };

  const handleUpdateAccessibility = (id: number) => {
    labWarzApi.Event.updateAccessibility(id)
      .then(() =>
        setDataE(
          dataE.map((event) =>
            event.id === id ? { ...event, isActive: !event.isActive } : event,
          ),
        ),
      )
      .catch((error) => console.error(error));
  };

  const handleDeleteEvent = () => {
    setIsLoading(true);
    labWarzApi.Event.deleteEvent(selectedEvent.id)
      .then()
      .catch((error) => console.error(error))
      .finally(() => {
        refreshData()
          .then(() => setIsLoading(false))
          .finally(() => {
            setEvent(DEFAULT_EVENT_STATE);
            setSelectedEvent(null);
            setIsDeleteModalOpen(false);
          });
      });
  };

  useEffect(() => {
    setEvent({ ...event, url: event.name.toLowerCase().split(' ').join('-') });
  }, [event.name]);

  const CustomInput = (
    {
      value,
      onClick,
    }: {
      value: string;
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    },
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <button
        onClick={onClick}
        ref={ref}
        className={'event-modal__field__input-datepicker'}
      >
        {value}
      </button>
    );
  };

  const handleUpdateStartDate = (value: Date) => {
    setEvent({
      ...event,
      startDate: value,
    });
  };

  const handleUpdateEndDate = (value: Date) => {
    setEvent({
      ...event,
      endDate: value,
    });
  };

  const handleUpdateName = (value: string) => {
    if (value.length > 0 && errorList.includes(ERROR_FIELD.REQUIRED)) {
      setErrorList(errorList.filter((error) => error !== ERROR_FIELD.REQUIRED));
    }
    if (value.length < 15 && errorList.includes(ERROR_FIELD.TITLE)) {
      setErrorList(errorList.filter((error) => error !== ERROR_FIELD.TITLE));
    }
    if (REGEX_NAME.test(value)) {
      setEvent({ ...event, name: value });
    }
  };

  const handleUpdateSelectedAdminList = (adminList: OptionType[]): void => {
    if (adminList.length > 0 && errorList.includes(ERROR_FIELD.ADMIN)) {
      setErrorList(errorList.filter((error) => error !== ERROR_FIELD.ADMIN));
    }
    setSelectedOptionList(adminList);
  };

  return (
    <>
      <LayoutInner>
        <section className={'global-admin'}>
          <section className={'global-admin__form'}>
            <div className={'event-modal'}>
              <div className={'event-modal__field'}>
                <span>{GLOBAL_ADMIN.modal.title}:</span>{' '}
                <input
                  type={'text'}
                  value={event.name}
                  className={'event-modal__field__input'}
                  onChange={(e) => handleUpdateName(e.target.value)}
                />
                {errorList.includes(ERROR_FIELD.REQUIRED) ? (
                  <span className={'event-modal__field__error'}>
                    {ERROR_MESSAGE[ERROR_FIELD.REQUIRED]}
                  </span>
                ) : null}
                {errorList.includes(ERROR_FIELD.TITLE) ? (
                  <span className={'event-modal__field__error'}>
                    {ERROR_MESSAGE[ERROR_FIELD.TITLE]}
                  </span>
                ) : null}
              </div>
              <div className={'event-modal__field'}>
                <span>{GLOBAL_ADMIN.modal.url}:</span>
                <input
                  type={'text'}
                  value={event.url}
                  disabled
                  className={'event-modal__field__input'}
                />
              </div>
              <div className={'event-modal__field'}>
                <span>{GLOBAL_ADMIN.modal.startDate}:</span>
                <DatePicker
                  preventOpenOnFocus={true}
                  showTimeSelect
                  dateFormat="MM/dd/yyyy h:mm aa"
                  selected={event.startDate}
                  customInput={React.createElement(
                    React.forwardRef(CustomInput),
                  )}
                  onChange={(date) => handleUpdateStartDate(date)}
                />
              </div>
              <div className={'event-modal__field'}>
                <span>{GLOBAL_ADMIN.modal.endDate}:</span>
                <DatePicker
                  preventOpenOnFocus={true}
                  showTimeSelect
                  dateFormat="MM/dd/yyyy h:mm aa"
                  selected={event.endDate}
                  minDate={event.startDate ? event.startDate : null}
                  customInput={React.createElement(
                    React.forwardRef(CustomInput),
                  )}
                  onChange={(date) => handleUpdateEndDate(date)}
                />
              </div>
              <div className={'event-modal__field'}>
                <span>{GLOBAL_ADMIN.modal.timeZone}:</span>
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={setSelectedTimezone}
                />
              </div>
              <div className={'event-modal__field'}>
                <span>{GLOBAL_ADMIN.modal.eventAdmins}:</span>

                <PeoplePicker
                  selectedOptionList={selectedOptionList}
                  setSelectedOptionList={(adminList: OptionType[]) =>
                    handleUpdateSelectedAdminList(adminList)
                  }
                />
                {errorList.includes(ERROR_FIELD.ADMIN) ? (
                  <span className={'event-modal__field__error'}>
                    {ERROR_MESSAGE[ERROR_FIELD.ADMIN]}
                  </span>
                ) : null}
              </div>
            </div>
            <div className={'global-admin__buttons-panel'}>
              <Button label={buttonLabel} onClick={handleSubmitEvent} />
              <span
                className={'global-admin__buttons-panel__clear'}
                onClick={() => {
                  setSelectedTimezone(DEFAULT_TIMEZONE);
                  setSelectedOptionList([]);
                  setButtonLabel(GLOBAL_ADMIN.buttons.add);
                  setEvent(DEFAULT_EVENT_STATE);
                }}
              >
                {GLOBAL_ADMIN.buttons.remove}
              </span>
            </div>
          </section>
          <section className={'global-admin__table'}>
            {isLoading ? <Loading /> : null}
            {dataE.length > 0 && (
              <SimpleTable
                columnList={COLUMN_LIST}
                data={dataE}
                isActionBlocked={isLoading}
                onDelete={handleOpenModal}
                onUpdate={handleUpdateExistingEvent}
                onUpdateAccessibility={handleUpdateAccessibility}
              />
            )}
          </section>
        </section>
      </LayoutInner>
      {selectedEvent && isDeleteModalOpen && (
        <CustomModal
          confirmAction={handleDeleteEvent}
          cancelAction={() => setIsDeleteModalOpen(false)}
          object={selectedEvent.name}
          warningText={'Would you like to delete Event '}
        />
      )}
    </>
  );
};
