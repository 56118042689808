import { Text } from '@components/kit/Text/Text';
import React from 'react';

export const INFO = {
  welcome: 'Welcome to LabWarz!',
  audio:
    'Playing the game is simple! There’s some videos with important information in them so make sure you’ve got your sound turned up or have headphones on.',
  scoring:
    'Scoring is based on correct quiz answers, fastest times, and the least incorrect clicks on the screen. During the game, some videos will play with instructions about what your next tasks will include. Listen carefully! While the video is playing, time is frozen and scoring is not affected. You can replay the videos without affecting your score.',
  controls:
    'You’ll only need to left mouse click. In simulation steps, you’re going to be pointing the mouse at the correct place on the screen where the configuration step would take place. In the real world, right clicks of the mouse sometimes reveal menus where you can select additional options. For the purposes of the LabWarz game, you’ll never need to right click. Find the place on the screen that will take you to the next step to score points.',
  quiz: (
    <p>
      <Text weight={600}>Quiz questions</Text> – some of the scenarios involve
      multiple choice quiz questions. When prompted with a question select the
      answer you think is correct and the game will proceed. If you selected
      properly, you’ll earn points
    </p>
  ),
  simulation: {
    main: (
      <p>
        <Text weight={600}>Simulation steps</Text> – throughout the game you’ll
        be presented a simulation of Veeam’s interface with a reminder of what
        your current task is. Left click in the correct place on the screen
        where you would need to click to configure the software to complete your
        task or move on to the next step of your configuration task.
      </p>
    ),
    hint: 'If you click in the wrong place a HINT will be displayed next to the Time Left indicator. Float your mouse over this hint and you’ll be provided more detailed instructions on where you need to click.',
    highlight:
      'Clicking two more times in the incorrect places will cause the game to highlight the area you need to click. You must click in this area to proceed. Once the correct place is highlighted for you, points will no longer be awarded for this particular step but you can still earn points on the following steps.',
  },
  finish:
    'When you finish the game, your score will be displayed. Check back and view the scoreboard to see if you’re still in a top spot to win!',
  TandCVirtual: 'Terms and Conditions of Use for Virtual VeeamOn Attendees',
  TandCOnSite: 'Terms and Conditions of Use for In-Person Attendees',
  TandCVirtualLink:
    'https://go.veeam.com/rs/870-LBG-312/images/Terms-and-Conditions_LabWarz_VeeamON_Update2022.pdf',
  TandCOnSiteLink:
    'https://psr.veeam.com/local/veeamon/2022/files/labwarz_inperson_terms_and_conditions.pdf',
  confirm: "Let's Go!",
};
