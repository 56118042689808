import React, { useEffect, useState } from 'react';
import Global from '@components/Global/Global';
import { IEvent } from '@root/api/api.interface';
import { labWarzApi } from '@root/api/LabWarzApi';

const GlobalPage = (): JSX.Element => {
  const [eventList, setEventList] = useState<IEvent[]>([]);
  useEffect(() => {
    labWarzApi.Event.getEventList()
      .then((res) => {
        setEventList(res.data);
      })
      .catch((error) => console.error(error));
  }, []);

  return <Global eventList={eventList} />;
};

export default GlobalPage;
