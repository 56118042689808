import React, { useEffect, useState } from 'react';
import './Global.scss';
import { IEvent } from '@root/api/api.interface';
import EventCard from '@components/EventCard/EventCard';
import { DateService } from '@root/services/Date.service';

interface IGlobalProps {
  eventList: IEvent[];
}

const Global = ({ eventList }: IGlobalProps): JSX.Element => {
  const [passedEventList, setPassedEventList] = useState<IEvent[]>([]);
  const [futureEventList, setFutureEventList] = useState<IEvent[]>([]);

  useEffect(() => {
    const now = new Date();
    setFutureEventList(
      eventList
        .filter(
          (event) =>
            DateService.calculateTimeOfCurrentTimezone(
              new Date(event.endDate),
              event.timeZone,
            ) > now,
        )
        .sort((a, b) => (a.startDate > b.startDate ? 1 : -1)),
    );
    setPassedEventList(
      eventList
        .filter(
          (event) =>
            DateService.calculateTimeOfCurrentTimezone(
              new Date(event.endDate),
              event.timeZone,
            ) < now,
        )
        .sort((a, b) => (a.startDate > b.startDate ? 1 : -1)),
    );
  }, [eventList]);

  return (
    <section className={'global'}>
      {futureEventList.map((event) => (
        <EventCard key={event.id + event.name} event={event} />
      ))}
      {passedEventList.map((event) => (
        <EventCard key={event.id + event.name} event={event} />
      ))}
    </section>
  );
};

export default Global;
