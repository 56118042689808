import React, { ReactNode } from 'react';
import './LayoutInner.scss';

interface ILayoutInnerProps {
  children: ReactNode;
}

export const LayoutInner = ({ children }: ILayoutInnerProps): JSX.Element => {
  return <section className={'layout-inner'}>{children}</section>;
};
