export const ERROR_PAGE = {
  error: {
    401: '401: Unauthorized Error',
    500: '500: Internal Server Error',
  },
  message: {
    401: 'You need to authorized firstly.',
    500: 'Something happened on our side!',
  },
  goMain: 'RETURN TO MAIN PAGE',
};
