import React, { useEffect, useState } from 'react';
import { Event } from '@components/Event/Event';
import { observer } from 'mobx-react-lite';
import { labWarzApi } from '@root/api/LabWarzApi';
import { useStore } from '@root/contextProvider/storeContext';
import Loading from '@components/Loading/Loading';
import { ADMIN_ROLE } from '@root/store/Store.const';
import { DateService } from '@root/services/Date.service';

export const EventPage = observer((): JSX.Element => {
  const [isEventOpen, setIsEventOpen] = useState<boolean | null>(null);

  const store = useStore();

  //Check if Event is open
  useEffect(() => {
    if (store.currentEvent) {
      const currentDate = new Date();
      const isOpen =
        currentDate >
          DateService.calculateTimeOfCurrentTimezone(
            new Date(store.currentEvent.startDate),
            store.currentEvent.timeZone,
          ) &&
        currentDate <
          DateService.calculateTimeOfCurrentTimezone(
            new Date(store.currentEvent.endDate),
            store.currentEvent.timeZone,
          );
      setIsEventOpen(isOpen);
    }
  }, [store.currentEvent]);

  //Load rights for User in the particular Event
  useEffect(() => {
    if (store.user && store.currentEvent && store.user.role !== 'ADMIN') {
      labWarzApi.Event.isUserAdmin(
        store.currentEvent.id,
        store.user.email,
      ).then((res) => {
        if (res.data) store.setIsAdmin(ADMIN_ROLE.ADMIN);
      });
    }
  }, [store.currentEvent, store.user]);

  //Load settings for the particular Event
  useEffect(() => {
    if (store.currentEvent) {
      labWarzApi.Event.getEventSettings(store.currentEvent.id).then((res) => {
        store.setShowUsersScore(res.data.showScore);
      });
      labWarzApi.Scenario.getScenarioList(store.currentEvent.id).then((res) => {
        const isFirstScenario = res.data[0].scenarioId === 1;

        store.setFirstScenarioAvailability(
          isFirstScenario ? res.data[0].isAvailable : res.data[1].isAvailable,
        );
        store.setSecondScenarioAvailability(
          !isFirstScenario ? res.data[0].isAvailable : res.data[1].isAvailable,
        );
      });
    }
  }, [store.currentEvent]);

  return !store.currentEvent ? (
    <Loading />
  ) : (
    <Event
      isEventOpen={isEventOpen}
      isOnSiteOpened={store.isScenario2Active}
      isVirtualOpened={store.isScenario1Active}
    />
  );
});
